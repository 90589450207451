export const sealedWaxBoxTypeOptions = [
  {
    id: "any-boxes",
    label: "Any Boxes",
    value: "(box,boxes) -case",
  },
  {
    id: "any-cases",
    label: "Any Cases",
    value: "(case,cases) -hit",
  },
  {
    id: "any-packs",
    label: "Any Packs",
    value: "(pack,packs) -(box,case)",
  },
  {
    id: "hobby-box",
    label: "Hobby Box",
    value: "hobby (box,boxes) -(case,blaster,fat,cello,hanger,mega)",
  },
  {
    id: "hobby-box-case",
    label: "Hobby Box Case",
    value: "hobby case -(hit,blaster,fat,cello,hanger,mega)",
  },
  {
    id: "jumbo-box",
    label: "Jumbo Box",
    value: "jumbo (box,boxes) -(case,fat,hanger,cello,mega)",
  },
  {
    id: "jumbo-box-case",
    label: "Jumbo Box Case",
    value: "jumbo case -(fat,hanger,cello,mega)",
  },
  {
    id: "blaster-box",
    label: "Blaster Box",
    value: "blaster (box,boxes) -(case,cello,hobby,fat,hanger,mega)",
  },
  {
    id: "blaster-box-case",
    label: "Blaster Box Case",
    value: "blaster case -(cello,hobby,fat,hanger,mega)",
  },
  {
    id: "hanger-box",
    label: "Hanger Box",
    value: "hanger (box,boxes) -(case,cello,hobby,fat,blaster,mega)",
  },
  {
    id: "hanger-box-case",
    label: "Hanger Box Case",
    value: "hanger case -(cello,hobby,fat,blaster,mega)",
  },
  {
    id: "cello-box",
    label: "Cello Box",
    value: "cello (box,boxes) -(case,hanger,hobby,fat,blaster,mega)",
  },
  {
    id: "cello-box-case",
    label: "Cello Box Case",
    value: "cello case -(hanger,hobby,fat,blaster,mega)",
  },
  {
    id: "retail-box",
    label: "Retail Box",
    value: "retail (box,boxes) -(case,hanger,hobby,fat,blaster,cello,mega)",
  },
  {
    id: "retail-box-case",
    label: "Retail Box Case",
    value: "retail case -(hit,hanger,hobby,fat,blaster,cello)",
  },
  {
    id: "mega-box",
    label: "Mega Box",
    value:
      "mega box -(case,hanger,hobby,fat,blaster,cello,auto) -(refractor,refractors,mojo,prospects,prospect,sgc,bgs,psa)",
  },
  {
    id: "mega-box-case",
    label: "Mega Box Case",
    value:
      "mega case -(hanger,hobby,fat,blaster,cello,auto) -(refractor,refractors,mojo,prospects,prospect,sgc,bgs,psa)",
  },
  {
    id: "fat-pack",
    label: "Fat Pack",
    value: "fat pack -(box,case)",
  },
  {
    id: "fat-packs-case",
    label: "Fat Packs Case",
    value: "fat (pack,packs) case -hit",
  },
  {
    id: "jumbo-pack",
    label: "Jumbo Pack",
    value: "jumbo pack -(box,case)",
  },
  {
    id: "jumbo-packs-case",
    label: "Jumbo Packs Case",
    value: "jumbo (pack,packs) case -hit",
  },
  {
    id: "value-pack",
    label: "Value Pack",
    value: "value pack -(box,case)",
  },
  {
    id: "value-packs-case",
    label: "Value Packs Case",
    value: "value (pack,packs) case -hit",
  },
  {
    id: "cello-pack",
    label: "Cello Pack",
    value: "cello pack -(box,case)",
  },
  {
    id: "cello-packs-case",
    label: "Cello Packs Case",
    value: "cello (pack,packs) case -hit",
  },
];

export const SEALED_WAX_BOX_TYPE_DEFAULT_LABEL = sealedWaxBoxTypeOptions[0].label;
export const SEALED_WAX_BOX_TYPE_DEFAULT_VALUE = sealedWaxBoxTypeOptions[0].value;
