import { useState } from "react";
import createUseContext from "constate";

function useModal() {
  const [isUpgradeTierModalVisible, setIsUpgradeTierModalVisible] = useState(false);
  const [upgradeTierModalObject, setUpgradeTierModalObject] = useState(null);
  const [isPlayerNotesModalVisible, setIsPlayerNotesModalVisible] = useState(false);
  const [playerNotes, setPlayerNotes] = useState();

  const [isPlayerModalVisible, setIsPlayerModalVisible] = useState(false);
  const [playerModalSelected, setPlayerModalSelected] = useState(null);

  const [isSetModalVisible, setIsSetModalVisible] = useState(false);
  const [setModalSelected, setSetModalSelected] = useState(null);

  const [isSetVariationModalVisible, setIsSetVariationModalVisible] = useState(false);
  const [setVariationModalSelected, setSetVariationModalSelected] = useState(null);

  const [isGradeModalVisible, setIsGradeModalVisible] = useState(false);
  const [gradeModalSelected, setGradeModalSelected] = useState(null);

  const [isVariationModalVisible, setIsVariationModalVisible] = useState(false);
  const [variationModalSelected, updateVariationModalSelected] = useState(null);

  const [isSaveToFavoriteModalVisible, setIsSaveToFavoriteModalVisible] = useState(false);
  const [saveToFavoriteData, setSaveToFavoriteData] = useState(null);

  const [isPopCountChartModalVisible, setIsPopCountChartModalVisible] = useState(false);
  const [popCountChartCardId, setPopCountChartCardId] = useState(null);

  const [isQuickChartModalVisible, setIsQuickChartModalVisible] = useState(false);

  const resetUpgradeTierModal = () => {
    setIsUpgradeTierModalVisible(false);
    setUpgradeTierModalObject(null);
  };

  const togglePlayerNotesModal = () => {
    setIsPlayerNotesModalVisible((prev) => !prev);
  };

  const setPlayerNotesData = (data) => {
    setPlayerNotes(data);
  };

  return {
    isUpgradeTierModalVisible,
    setIsUpgradeTierModalVisible,
    upgradeTierModalObject,
    setUpgradeTierModalObject,
    resetUpgradeTierModal,
    isPlayerNotesModalVisible,
    togglePlayerNotesModal,
    playerNotes,
    setPlayerNotesData,
    isPlayerModalVisible,
    setIsPlayerModalVisible,
    playerModalSelected,
    setPlayerModalSelected,
    isSetModalVisible,
    setIsSetModalVisible,
    setModalSelected,
    setSetModalSelected,
    isSetVariationModalVisible,
    setIsSetVariationModalVisible,
    setVariationModalSelected,
    setSetVariationModalSelected,
    isGradeModalVisible,
    setIsGradeModalVisible,
    gradeModalSelected,
    setGradeModalSelected,
    isVariationModalVisible,
    setIsVariationModalVisible,
    variationModalSelected,
    updateVariationModalSelected,
    isSaveToFavoriteModalVisible,
    setIsSaveToFavoriteModalVisible,
    saveToFavoriteData,
    setSaveToFavoriteData,
    isPopCountChartModalVisible,
    setIsPopCountChartModalVisible,
    popCountChartCardId,
    setPopCountChartCardId,
    isQuickChartModalVisible,
    setIsQuickChartModalVisible,
  };
}

const [ModalProvider, useModalContext] = createUseContext(useModal);

export { ModalProvider, useModalContext };
