import React from "react";
import { Tooltip } from "antd";
import { isMobile } from "react-device-detect";

import Helpers from "../services/helpers";
import { useModalContext } from "./contexts/modalcontext";

const PopulationDisplay = ({ id, pop, pop_date, style = {}, disableSpace = false }) => {
  if (!pop) return null;
  const trigger = isMobile ? "click" : "hover";

  const { setIsPopCountChartModalVisible, setPopCountChartCardId } = useModalContext();

  const handleClick = () => {
    setIsPopCountChartModalVisible(true);
    setPopCountChartCardId(id);
  };

  return (
    <Tooltip title={`Last Updated: ${Helpers.formatDateWithTime(pop_date, false)}`} trigger={trigger}>
      {disableSpace ? (
        <span style={style} onClick={handleClick}>
          (<a style={{ textDecoration: "underline" }}>pop: {pop}</a>)
        </span>
      ) : (
        <span style={style} onClick={handleClick}>
          &nbsp;(<a style={{ textDecoration: "underline" }}>pop: {pop}</a>)
        </span>
      )}
    </Tooltip>
  );
};

export default PopulationDisplay;
