import React from "react";
import get from "lodash/get";

import { AffiliateLink } from "../components/AffiliateLink";
import { ChartLink } from "../components/ChartLink";

import { ENTITY_TYPES } from "./constants";
import PopulationDisplay from "../components/PopulationDisplay";

import Helpers from "../services/helpers";

export const HIGHLIGHT_SET_VARIATION = "set_variation";
export const HIGHLIGHT_VARIATION = "variation";
export const HIGHLIGHT_BOX_TYPE = "box_type";
export const HIGHLIGHT_GRADE = "grade";
export const HIGHLIGHT_PLAYER = "player";

export const generateCardTitle = (card, options = {}) => {
  const variation = get(card, "variation.name", card.variation);
  const grade = get(card, "grade.name", card.grade);
  const specificQualifier = card.specific_qualifier ? `(${card.specific_qualifier})` : "";
  const cardNumber = card.card_number ? `#${card.card_number}` : "";
  const printRun = card.set_variation && card.set_variation.print_run ? card.set_variation.print_run : "";

  const cardTitleArr = [
    !options.hidePlayer && card.player && card.player.name,
    !options.hideSet && Helpers.generateSetName(card.card_set, options),
    !options.hideCardNumber && cardNumber,
    !options.hideVariation && variation,
    !options.hideVariation && printRun,
    !options.hideSpecificQualifier && specificQualifier,
    !options.hideGrade && grade,
  ];

  return cardTitleArr.filter(Boolean).join(" ");
};

export const generateCardTitleWOGrade = (card) => {
  const variation = card.variation && card.variation.name;
  const specificQualifier = card.specific_qualifier;
  const cardNumber = card.card_number;

  const cardTitleArr = [card.player.name, Helpers.generateSetName(card.card_set), variation];

  if (card.set_variation && card.set_variation.print_run) cardTitleArr.push(`${card.set_variation.print_run}`);
  if (cardNumber) cardTitleArr.push(`#${cardNumber}`);
  if (specificQualifier) cardTitleArr.push(`(${specificQualifier})`);

  return cardTitleArr.join(" ");
};

export const generateCardTitleWOPlayerName = (card) => {
  const variation = card.variation && card.variation.name;
  const grade = card.grade && card.grade.name;
  const specificQualifier = card.specific_qualifier;

  const cardTitleArr = [Helpers.generateSetName(card.card_set), variation];

  if (card.set_variation && card.set_variation.print_run) cardTitleArr.push(`${card.set_variation.print_run}`);
  if (specificQualifier) cardTitleArr.push(`(${specificQualifier})`);
  cardTitleArr.push(grade);

  return cardTitleArr.join(" ");
};

export const generateCardTitleFormatted = (card) => {
  return (
    <>
      <div className="player-name">{card.player.name}</div>
      <div className="card-meta">{generateCardTitleWOPlayerName(card)}</div>
    </>
  );
};

export const _genRatioTitle = ({
  ids,
  queryRangeId,
  entity,
  entityType = ENTITY_TYPES.CARD,
  highlight,
  color,
  includeIcons = false,
}) => {
  let parts;

  switch (entityType) {
    case ENTITY_TYPES.CARD: {
      const variation = entity.variation?.name;
      const grade = entity.grade?.name;
      const player = entity.player ? entity.player.name : "";
      const specificQualifier = entity.specific_qualifier ? `(${entity.specific_qualifier})` : "";
      const cardNumber = entity.card_number ? `#${entity.card_number}` : "";
      const printRun = entity.set_variation && entity.set_variation.print_run ? entity.set_variation.print_run : "";
      parts = [
        player,
        `${entity.card_set.year} ${entity.card_set.name}`,
        cardNumber,
        variation,
        printRun,
        specificQualifier,
        grade,
      ];

      if (highlight === HIGHLIGHT_PLAYER) {
        parts.splice(
          0,
          1,
          <strong key={`player-${entity.player_id}`} style={{ color }}>
            {player}
          </strong>,
        );
      } else if (highlight === HIGHLIGHT_VARIATION) {
        parts.splice(
          3,
          1,
          <strong key={`variation-${entity.variation_id}`} style={{ color }}>
            {variation}
          </strong>,
        );
      } else if (highlight === HIGHLIGHT_SET_VARIATION) {
        parts.splice(1, 1, entity.card_set.year);
        parts.splice(
          3,
          2,
          <strong key={`set_variation-${entity.set_variation_id}`} style={{ color }}>
            {entity.card_set.name} {variation} {printRun}
          </strong>,
        );
      } else if (highlight === HIGHLIGHT_GRADE) {
        parts.splice(
          6,
          1,
          <strong key={`grade-${entity.grade_id}`} style={{ color }}>
            {grade}
          </strong>,
        );
      }
      break;
    }

    case ENTITY_TYPES.SEALED_WAX: {
      parts = [entity.card_set?.year, entity.card_set?.name, entity.card_set?.sport?.name, entity.box_type?.name];

      if (highlight === HIGHLIGHT_BOX_TYPE) {
        parts.splice(
          3,
          1,
          <strong key={`box_type-${entity.box_type_id}`} style={{ color }}>
            &nbsp;{entity.box_type?.name}
          </strong>,
        );
      }
      break;
    }

    default:
      break;
  }

  return (
    <span>
      {parts.map((part) => {
        if (typeof part === "string") {
          return ` ${part}`;
        }

        return part;
      })}
      {entity.population_count && (
        <PopulationDisplay
          id={entity.id}
          pop={entity.population_count}
          pop_date={entity.population_last_updated}
          serialNumber={entity.serial_number}
        />
      )}
      {includeIcons ? (
        <>
          <AffiliateLink entityType={entityType} query={entity.query} />
          <ChartLink
            entityType={entityType}
            cardId={ids?.length ? ids.join(",") : entity.id}
            queryRangeId={queryRangeId}
            record={entity}
            style={{ padding: 0 }}
          />
        </>
      ) : null}
    </span>
  );
};

export const getRatioListEntityData = (ratio, entities, entityType = ENTITY_TYPES.CARD) => {
  const [identifier, statsIdentifier] = {
    [ENTITY_TYPES.CARD]: ["cards", "historical_stats"],
    [ENTITY_TYPES.SEALED_WAX]: ["waxes", "sealed_wax_stats"],
  }[entityType];

  const [ratioEntity1, ratioEntity2] = ratio[identifier];
  const e1 = entities.find((e) => e.id === ratioEntity1.id);
  const e2 = entities.find((e) => e.id === ratioEntity2.id);

  if (!e1 || !e2) {
    return;
  }

  const e1EndAvgValue = ratioEntity1.end_avg;
  const e2EndAvgValue = ratioEntity2.end_avg;

  const shouldReverse = e1EndAvgValue < e2EndAvgValue;

  const [entity1, entity2] = shouldReverse ? [e2, e1] : [e1, e2];
  const [entity1EAV, entity2EAV] = shouldReverse ? [e2EndAvgValue, e1EndAvgValue] : [e1EndAvgValue, e2EndAvgValue];

  const [ls1, ls2] = {
    [ENTITY_TYPES.CARD]: [entity1[statsIdentifier].last_sale, entity2[statsIdentifier].last_sale],
    [ENTITY_TYPES.SEALED_WAX]: [entity1[statsIdentifier]?.last_sale, entity2[statsIdentifier]?.last_sale],
  }[entityType];

  const endDate1 = Helpers.formatDateWithTime(ls1, false);
  const endDate2 = Helpers.formatDateWithTime(ls2, false);

  return {
    key: `ratio-${entity1.id}-${entity2.id}`,
    entity1Name: _genRatioTitle({
      entity: entity1,
      entityType,
      highlight: ratio.type,
      color: "green",
    }),
    entity1LastAvgSalePrice: `${Helpers.formatMoney(entity1EAV)} (${endDate1})`,
    entity2Name: _genRatioTitle({
      entity: entity2,
      entityType,
      highlight: ratio.type,
      color: "red",
    }),
    entity2LastAvgSalePrice: `${Helpers.formatMoney(entity2EAV)} (${endDate2})`,
    ratio12: Math.round((entity1EAV / entity2EAV) * 100) / 100,
    ratio21: Math.round((entity2EAV / entity1EAV) * 100) / 100,
    playerId: entity1.player_id?.toString(),
  };
};

export const getPopCountRatioListCardData = (card) => {
  const cardEndAvgValue = card.historical_stats ? card.historical_stats.all_end_avg : 0;
  const endDate = Helpers.formatDateWithTime(card.historical_stats.last_sale, false);
  return {
    key: `pop-count-ratio-${card.id}`,
    name: generateCardTitle(card, { hideSport: true }),
    avgSalePrice: `${Helpers.formatMoney(cardEndAvgValue)} (${endDate})`,
    popCount: card.population_count,
    ratio: Math.round((cardEndAvgValue / card.population_count) * 100) / 100,
  };
};

export const getHighlightedText = (text, highlight, options = {}) => {
  const ORANGE = "#FF781F";
  const boldOptions = options.bold ? options.bold.map((k) => k.toLowerCase()) : null;

  const splittedHighlight = highlight.replace(/\(/, "\\(").replace(/\)/, "\\)").trim().split(" ");
  const highlightOrRegex = splittedHighlight.join("|");
  const boldOrRegex = boldOptions ? `${boldOptions.join("|")}` : "";

  const parts = text
    ? text.split(new RegExp(`(${[highlightOrRegex, boldOrRegex].filter(Boolean).join("|")})`, "gi"))
    : [];
  return (
    <span>
      {parts.map((part, i) => {
        const style = {};

        const isPrimaryMatch = splittedHighlight.some(
          (highlightPart) => part.toLowerCase() === highlightPart.toLowerCase(),
        );
        const isBoldMatch = boldOptions ? boldOptions.includes(part.toLowerCase()) : false;

        if (isPrimaryMatch) {
          style.fontWeight = 600;
          style.color = ORANGE;
        }
        if (isBoldMatch) {
          style.fontWeight = 600;
        }

        return (
          <span key={i} style={style}>
            {part}
          </span>
        );
      })}
    </span>
  );
};
