import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

import moment from "moment-timezone";
import groupBy from "lodash/groupBy";
import uniqBy from "lodash/uniqBy";
import get from "lodash/get";
import zip from "lodash/zip";
import cloneDeep from "lodash/cloneDeep";

import currency from "currency.js";
import { ERRORS, LOW_FEEDBACK_THRESHOLD } from "../globals";
import { searchGradeOptions, gradesIndexMap, gradesRegExp } from "../dataValues/SearchGradeOptions";
import { sealedWaxBoxTypeOptions } from "../components/layouts/livesearchpane/options.js";
import { generateCardTitleWOGrade } from "../utils/cardList";

export const multiverseSalesPlatforms = ["pwcc", "goldin_auctions"];
export const salesPlatfromNames = {
  goldin_auctions: "Goldin",
  pwcc: "PWCC",
};

const parseCards = (cards, startDate, endDate, globalState) => {
  return cards.map((card) => updateCard(cloneDeep(card), startDate, endDate, globalState));
};

const isAuction = (listing_type) => {
  const lt = listing_type.toLowerCase();
  return lt === "auction" || lt === "auctionbin" || lt === "auctionwithbin";
};

const calculateResultType = (result) => {
  if (result.best_offer_enabled && result.offer_price && result.offer_price < result.selling_price) {
    return "Best Offer";
  }
  if (result.listing_type) {
    switch (result.listing_type.toLowerCase()) {
      case "auction":
        return "Auction";
      case "auctionbin":
      case "auctionwithbin":
        return "Buy It Now";
      case "fixedprice":
        return "Fixed";
      case "storeinventory":
        return "Store";
    }
    return result.listing_type;
  } else {
    return "NA";
  }
};

const getAuctionTypes = () => {
  return ["Best Offer", "Auction", "Buy It Now", "Fixed", "Store"];
};

const formattedAuctionTypes = {
  ["Auction"]: "Auction",
  ["Buy It Now"]: "AuctionWithBIN",
  ["Fixed"]: "FixedPrice",
  ["Store"]: "StoreInventory",
  ["Unspecified"]: "Unspecified",
};

const updateCard = (card, startDate, endDate, globalState) => {
  // If some filter has gotten rid of all the results for this card, stats
  // can't be updated
  card.card_results_data = { day: [], week: [], month: [] };
  if (!card.card_results?.length) {
    return card;
  }

  //calculate min, max, avg
  const dataObj = { day: {}, week: {}, month: {} };
  const cardStats = {
    total: 0,
    count: 0,
    last14_total: 0,
    last14_count: 0,
    salesVolumePerDay: {},
    percentagePerDay: {},
    totalSales: 0,
  };
  const the_start = moment(endDate).subtract(14, "days");
  const the_end = moment(endDate);
  const card_results = card.card_results
    .filter((result) => moment(result.end_time).isBetween(startDate, endDate, "day", "[]"))
    .filter((result) => filterCardResultNoFeedback(result, globalState.excludeNoFeedback))
    .filter((result) => filterCardResultBySeller(result, globalState.excludeSellersFromChartResults))
    .filter((result) => filterCardResultByPlatform(result, globalState.excludePlatformsFromChartResults))
    .filter((result) => filterCardResultLowFeedback(result, globalState.excludeLowFeedback))
    .filter((result) => (globalState.excludeBuyItNow ? filterExcludeBuyItNow(result) : true))
    .filter((result) => (globalState.excludeAuctions ? filterExcludeAuctions(result) : true))
    .filter((result) => (globalState.excludeSaleWithMakeOffer ? filterExcludeSaleWithMakeOffer(result) : true))
    .sort((a, b) => moment(a.end_time).unix() - moment(b.end_time).unix());

  card.card_results = card_results;

  if (!card_results.length) {
    return card;
  }

  const processTimespanItem = ({ result, cardId, label, item }) => {
    if (!item) {
      return;
    }

    const summary = {
      sortValue: label,
      name: item.name,
    };

    const total = item.values.reduce((a, b) => a + b, 0);

    summary[`${cardId}_min`] = Math.min(...item.values);
    summary[`${cardId}_avg`] = total / item.values.length;
    summary[`${cardId}_max`] = Math.max(...item.values);
    summary[`${cardId}_total`] = total;
    summary[`${cardId}_count`] = item.values.length;
    summary[`${cardId}_sales_volume`] = item.salesVolume;

    if (!result.initialPrice) {
      result.initialPrice = summary[`${cardId}_avg`];
    }

    summary[`${cardId}_percentage`] = (summary[`${cardId}_avg`] * 100) / result.initialPrice - 100;
    summary[`${cardId}_totalsales`] = summary[`${cardId}_avg`] * item.salesVolume;

    result.data.push(summary);
  };

  const processData = ({ data, label, xAxisLabel, price }) => {
    if (!data[label]) {
      data[label] = {
        name: xAxisLabel,
        values: [price],
        salesVolume: 1,
      };
    } else {
      data[label].values.push(price);
      data[label].salesVolume += 1;
    }
  };

  card_results.forEach((auction) => {
    if (auction.included !== false) {
      cardStats.count++;
      const auction_moment_date = moment(auction.end_time);
      // Determine which price to use
      const price = auction.offer_price ? Number(auction.offer_price) : Number(auction.selling_price);
      const dayLabel = `day_${auction_moment_date.format("YYYYMMDD")}`;

      processData({
        data: dataObj.day,
        label: dayLabel,
        xAxisLabel: auction_moment_date.format("M/D/YYYY"),
        price,
      });

      processData({
        data: dataObj.week,
        label: `week_${moment(auction.end_time).startOf("week").format("YYYYMMDD")}`,
        xAxisLabel: auction_moment_date.format("M/D/YYYY"),
        price,
      });

      processData({
        data: dataObj.month,
        label: `month_${moment(auction.end_time).startOf("month").format("YYYYMMDD")}`,
        xAxisLabel: auction_moment_date.format("M/YYYY"),
        price,
      });

      if (!cardStats.min || cardStats.min > price) cardStats.min = price;
      if (!cardStats.max || cardStats.max < price) cardStats.max = price;
      cardStats.salesVolumePerDay[dayLabel] = dataObj.day[dayLabel].salesVolume;

      cardStats.total += price;
      if (auction_moment_date.isBetween(the_start, the_end, "day", "[]")) {
        cardStats.last14_total += price;
        cardStats.last14_count++;
      }
    }
    auction.auction_type = calculateResultType(auction);
  });

  const resultsByTimespan = {
    day: {
      data: [],
      initialPrice: null,
    },
    week: {
      data: [],
      initialPrice: null,
    },
    month: {
      data: [],
      initialPrice: null,
    },
  };

  const childrenKeys = Object.keys(dataObj).map((k) => Object.keys(dataObj[k]));

  zip(...childrenKeys).forEach(([day, week, month]) => {
    processTimespanItem({
      result: resultsByTimespan.day,
      cardId: card.id,
      label: day,
      item: dataObj.day[day],
    });
    processTimespanItem({
      result: resultsByTimespan.week,
      cardId: card.id,
      label: week,
      item: dataObj.week[week],
    });
    processTimespanItem({
      result: resultsByTimespan.month,
      cardId: card.id,
      label: month,
      item: dataObj.month[month],
    });
  });

  resultsByTimespan.day.data.sort(sortResultData);
  resultsByTimespan.week.data.sort(sortResultData);
  resultsByTimespan.month.data.sort(sortResultData);

  if (resultsByTimespan.day.data.length > 0) {
    card.card_results_data = {
      day: resultsByTimespan.day.data,
      week: resultsByTimespan.week.data,
      month: resultsByTimespan.month.data,
    };
    cardStats.average = cardStats.total / cardStats.count;
    cardStats.startValues = card.card_results_data.day[0];
    cardStats.startAverageValue = cardStats.startValues[card.id + "_avg"];
    cardStats.endValues = card.card_results_data.day[card.card_results_data.day.length - 1];
    cardStats.endAverageValue = cardStats.endValues[card.id + "_avg"];
    cardStats.maxPercentChange = Math.max(...card.card_results_data.day.map((c) => c[card.id + "_percentage"]));
    cardStats.minPercentChange = Math.min(...card.card_results_data.day.map((c) => c[card.id + "_percentage"]));
    cardStats.lastSaleDate = card.historical_stats ? card.historical_stats.last_sale : null;
    card.card_stats = cardStats;
  }

  return card;
};

//https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#stackoverflow-archive-begin
function shadeHexColor(color, percent) {
  var f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    "#" +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
}

const getColorFromPalette = (index, total) => {
  const palettes = {
    c3: ["#011FFD", "#b600f8", "#08c400"],
    c4: ["#011FFD", "#b600f8", "#08c400", "#ff7c43"],
    c5: ["#011FFD", "#b600f8", "#08c400", "#ff7c43", "#bab43e"],
    c6: ["#011FFD", "#b600f8", "#08c400", "#ff7c43", "#ce0000", "#bab43e"],
    c7: ["#011FFD", "#b600f8", "#08c400", "#ff7c43", "#ce0000", "#440BD4", "#bab43e"],
    c8: ["#011FFD", "#b600f8", "#08c400", "#ff7c43", "#ce0000", "#440BD4", "#bab43e", "#35212a"],
  };
  let palette_to_use = palettes.c3;
  if (total > 3) {
    if (total > 8) {
      palette_to_use = palettes.c8;
    } else {
      palette_to_use = palettes["c" + total];
    }
  }

  const choice = index % palette_to_use.length;
  let selectedColor = palette_to_use[choice];
  const overrun = Math.floor(index / palette_to_use.length);
  if (overrun === 1) {
    selectedColor = shadeHexColor(selectedColor, 0.5);
  } else if (overrun === 2) {
    selectedColor = shadeHexColor(selectedColor, -0.5);
  }
  return selectedColor;
};

const getColorWithGradientFromPalette = (index, total) => {
  const palettes = {
    c3: [
      { start: "#011FFD", end: "#0066ff" },
      { start: "#b600f8", end: "#ff66ff" },
      { start: "#08c400", end: "#00ff00" },
    ],
    c4: [
      { start: "#011FFD", end: "#0066ff" },
      { start: "#b600f8", end: "#ff66ff" },
      { start: "#08c400", end: "#00ff00" },
      { start: "#ff7c43", end: "#ff6600" },
    ],
    c5: [
      { start: "#011FFD", end: "#0066ff" },
      { start: "#b600f8", end: "#ff66ff" },
      { start: "#08c400", end: "#00ff00" },
      { start: "#ff7c43", end: "#ff6600" },
      { start: "#bab43e", end: "#ffcc00" },
    ],
    c6: [
      { start: "#011FFD", end: "#0066ff" },
      { start: "#b600f8", end: "#ff66ff" },
      { start: "#08c400", end: "#00ff00" },
      { start: "#ff7c43", end: "#ff6600" },
      { start: "#ce0000", end: "#ff5050" },
      { start: "#bab43e", end: "#ffcc00" },
    ],
    c7: [
      { start: "#011FFD", end: "#0066ff" },
      { start: "#b600f8", end: "#ff66ff" },
      { start: "#08c400", end: "#00ff00" },
      { start: "#ff7c43", end: "#ff6600" },
      { start: "#ce0000", end: "#ff5050" },
      { start: "#440BD4", end: "#6666ff" },
      { start: "#bab43e", end: "#ffcc00" },
    ],
    c8: [
      { start: "#011FFD", end: "#0066ff" },
      { start: "#b600f8", end: "#ff66ff" },
      { start: "#08c400", end: "#00ff00" },
      { start: "#ff7c43", end: "#ff6600" },
      { start: "#ce0000", end: "#ff5050" },
      { start: "#440BD4", end: "#6666ff" },
      { start: "#bab43e", end: "#ffcc00" },
      { start: "#35212a", end: "#660066" },
    ],
  };

  let palette_to_use = palettes.c3;

  if (total > 3) {
    if (total > 8) {
      palette_to_use = palettes.c8;
    } else {
      palette_to_use = palettes["c" + total];
    }
  }

  const choice = index % palette_to_use.length;
  let selectedColor = palette_to_use[choice];
  const overrun = Math.floor(index / palette_to_use.length);

  if (overrun === 1) {
    selectedColor = {
      start: shadeHexColor(selectedColor.start, 0.5),
      end: shadeHexColor(selectedColor.end, 0.5),
    };
  } else if (overrun === 2) {
    selectedColor = {
      start: shadeHexColor(selectedColor.start, -0.5),
      end: shadeHexColor(selectedColor.end, -0.5),
    };
  }

  return selectedColor;
};

const getRandomHexColor = (primaryColor) => {
  const primary = primaryColor !== null ? primaryColor : Math.floor(Math.random() * 3);
  const hex =
    "#" + getRandomHexSingle(primary === 0) + getRandomHexSingle(primary === 1) + getRandomHexSingle(primary === 2);
  return hex;
};

const getRandomHexSingle = (isPrimary) => {
  const weight = isPrimary ? 200 : 255;
  const hexString = "0" + Math.floor(Math.random() * weight).toString(16);
  return hexString.substr(hexString.length - 2, hexString.length);
};

const getRandomWeightedHexColorByKey = (key) => {
  return getRandomWeightedHexColor(key.substring(key.indexOf("_")));
};

const getRandomWeightedHexColor = (weightedColor) => {
  let color = getRandomHexColor();

  if (
    weightedColor.toLowerCase() === "red" ||
    weightedColor.toLowerCase() === "_max" ||
    weightedColor.toLowerCase() === "max"
  ) {
    color = "#FF" + getRandomHexSingle() + getRandomHexSingle();
  }
  if (
    weightedColor.toLowerCase() === "green" ||
    weightedColor.toLowerCase() === "_avg" ||
    weightedColor.toLowerCase() === "avg"
  ) {
    color = "#" + getRandomHexSingle() + "FF" + getRandomHexSingle();
  }
  if (
    weightedColor.toLowerCase() === "blue" ||
    weightedColor.toLowerCase() === "_min" ||
    weightedColor.toLowerCase() === "min"
  ) {
    color = "#" + getRandomHexSingle() + getRandomHexSingle() + "FF";
  }
  return color;
};

const sortResultData = (a, b) => {
  if (a.sortValue < b.sortValue) {
    return -1;
  }
  if (a.sortValue > b.sortValue) {
    return 1;
  }
  return 0;
};

const mergeData = (data) => {
  const mergedData = {};
  let allData = [];
  for (const cardData of data) {
    if (cardData) {
      mergedData[cardData.sortValue] = { ...mergedData[cardData.sortValue], ...cardData };
    }
  }
  for (const property in mergedData) {
    allData.push(mergedData[property]);
  }
  allData = allData.sort(sortResultData);
  return allData;
};

const generateSetName = (set, options = {}) => {
  if (!set) return "-";

  const { name, year, sport } = set;
  const sportName = !options.hideSport && sport ? sport.name : "";
  const parts = options.reverse ? [name, sportName, year] : [year, name, sportName];

  return parts.filter(Boolean).join(" ");
};

const formatDateWithTime = (inputDate, includeTime = true, ignoreTimezone = false) => {
  // Sometimes the inputDate comes already formatted to a timezone and the moment() constructor will convert the
  // timezone, resulting in non-accurate formatting
  const baseMoment = ignoreTimezone ? moment.utc(inputDate) : moment(inputDate);
  return baseMoment.format(`MM/DD/YYYY${includeTime ? ` hh:mm A` : ""}`);
};

const formatDateWithTimeTZ = (inputDate, includeTime = true, includeTZIndicator = true) => {
  const dateStr = moment(inputDate)
    .tz("America/Chicago")
    .format(`MM/DD/YYYY${includeTime ? ` hh:mm A` : ""}`);

  return dateStr + (includeTZIndicator ? " CST" : "");
};

const formatMoney = (amount, precision = 2, symbol = "$") => currency(amount, { precision, symbol }).format();

const formatRange = (range) => {
  if (!range) return "";
  if (range.length === 0 || range.indexOf("-") === -1) return range;
  const rangeValues = range.split("-");
  return formatMoney(rangeValues[0].trim()) + " - " + formatMoney(rangeValues[1].trim());
};

const groupByKey = (arrayToSort, selector, key) => {
  let groups = [];
  const uniqueObj = {};
  arrayToSort.map((item) => {
    item.key = item[selector][key];
  });
  for (let i = 0; i < arrayToSort.length; i++) {
    const cardKey = arrayToSort[i].key;
    if (uniqueObj[cardKey]) {
      uniqueObj[cardKey].items.push(arrayToSort[i]);
    } else {
      uniqueObj[cardKey] = {};
      uniqueObj[cardKey][key] = arrayToSort[i][selector][key];
      uniqueObj[cardKey].items = [arrayToSort[i]];
    }
  }
  for (let unique in uniqueObj) {
    if (unique in uniqueObj) {
      groups.push(uniqueObj[unique]);
    }
  }
  return groups;
};

const defaultCardSort = (aCard, bCard) => {
  const aValue = aCard.player.name + aCard.card_set.year + aCard.card_set.name + aCard.variation + aCard.grade;
  const bValue = bCard.player.name + bCard.card_set.year + bCard.card_set.name + bCard.variation + bCard.grade;
  if (aValue < bValue) {
    return -1;
  }
  if (aValue > bValue) {
    return 1;
  }
  return 0;
};

const sortByFieldName = (field, reversed = false) => {
  return (a, b) => {
    if (get(a, field) < get(b, field)) {
      return reversed ? 1 : -1;
    }
    if (get(a, field) > get(b, field)) {
      return reversed ? -1 : 1;
    }
    return 0;
  };
};

const sortDateByFieldName = (field, reversed = false) => {
  return (a, b) => {
    if (moment(get(a, field)) < moment(get(b, field))) {
      return reversed ? 1 : -1;
    }
    if (moment(get(a, field)) > moment(get(b, field))) {
      return reversed ? -1 : 1;
    }
    return 0;
  };
};

const sortByFieldNameNested = (field1, field2) => {
  return (a, b) => {
    if (!a[field1] || !b[field1]) return 1;
    if (a[field1][field2] < b[field1][field2]) {
      return -1;
    }
    if (a[field1][field2] > b[field1][field2]) {
      return 1;
    }
    return 0;
  };
};

const sortByFieldNameNumeric = (field) => {
  return (a, b) => {
    return get(a, field) - get(b, field);
  };
};
const sortByFieldNameNumericReverse = (field) => {
  return (a, b) => {
    return get(b, field) - get(a, field);
  };
};

/*
 * If the price increased, use the formula [(New Price - Old Price)/Old Price] and then multiply that number by 100
 * If the price decreased, use the formula [(Old Price - New Price)/Old Price] and multiply that number by 100
 */
const getPercentChanged = (startValue, endValue, fractionDigits = 1) => {
  if (startValue === 0) {
    return 0;
  }

  const difference = endValue - startValue;
  if (difference === 0) {
    return 0;
  }

  if (difference > 0) {
    return (((endValue - startValue) / startValue) * 100).toFixed(fractionDigits);
  } else {
    return -(((startValue - endValue) / startValue) * 100).toFixed(fractionDigits);
  }
};

const getCardName = (card, includeId = false) => {
  const { player, card_set, variation, grade, id } = card;

  const cardParts = [
    player.name,
    card_set.year,
    card_set.name,
    get(variation, "name", variation),
    get(grade, "name", grade),
    includeId ? `(ID: ${id})` : "",
  ];

  return cardParts.filter(Boolean).join(" ");
};

const getSealedWaxName = (pack, options = {}) => {
  const { card_set, id } = pack;
  const type = pack.box_type?.name || pack.type;

  const cardParts = [
    !options.hideYear && card_set.year,
    !options.hideSetName && card_set.name,
    !options.hideSport && card_set.sport?.name,
    !options.hideBoxType && type,
    options.includeId ? `(ID: ${id})` : "",
  ];

  return cardParts.filter(Boolean).join(" ");
};

const unauthorizedRedirect = (router) => {
  router.push("/unauthorized");
};

const expiredRedirect = () => {
  // skip redirect in case auth flow is already taking an action
  if (window.location.pathname === "/wp-auth" || window.location.pathname.includes("/market-pulse/public")) {
    return;
  }
  window.location = `${publicRuntimeConfig.WP_HOST}/tft_sso/wp_verify?rt=login`;
};

const homeRedirect = (router) => {
  router.push("/");
};

const handleApiError = (e, router, fn) => {
  if (e.message === ERRORS.UNAUTHORIZED) {
    // in our case, unauthorized should really be that the token is expired so perform that
    expiredRedirect();
  }
  if (fn) {
    fn();
  }
};

const getTerms = (searchContext) => {
  return {
    players: searchContext.playersSelected.map((item) => item.value),
    sets: searchContext.setsSelected.map((item) => item.value),
    variations: searchContext.variationsSelected.map((item) => item.value),
    years: searchContext.yearsSelected.map((item) => item.value),
    grades: searchContext.gradesSelected.map((item) => item.value),
    sports: searchContext.sportsSelected.map((item) => item.value),
    setNameYears: searchContext.setNameYearsSelected.map((item) => item.value),
  };
};

const constructQuery = ({
  queryYear,
  queryPlayerName,
  querySet,
  querySport,
  queryVariation,
  queryOtherTerms,
  querySealedType,
  queryOtherTermsNewItem,
  queryExcludeTerms,
  queryExcludeTermsNewItem,
  queryGrade,
  queryCardNumber,
  queryExcludeLots,
  queryExcludeSealed,
  queryExcludeBreaks,

  shouldRemoveSportsFromSets,
}) => {
  let newQuery = [
    parseArray(queryYear),
    parseArray(queryPlayerName),
    queryCardNumber,
    parseArray(shouldRemoveSportsFromSets ? removeSportsFromSets(querySet) : querySet),
    parseArray(querySport),
    queryVariation ? (queryVariation.toLowerCase() === "base" ? "" : parseArray(queryVariation)) : "",
    queryOtherTerms.join(" "),
    parseArray(queryGrade),
  ].filter(Boolean);

  // if (querySetVariation) {
  // 	newQuery.push(querySetVariation);
  // } else {
  // 	newQuery.push(
  // 		parseArray(shouldRemoveSportsFromSets ? removeSportsFromSets(querySet) : querySet),
  // 		parseArray(queryVariation),
  // 	);
  // }

  if (querySealedType) {
    const option = sealedWaxBoxTypeOptions.find(({ id }) => id === querySealedType);
    newQuery.push(option ? option.value : querySealedType);
  }

  if (queryOtherTermsNewItem) {
    newQuery.push(queryOtherTermsNewItem);
  }
  newQuery = newQuery.join(" ");

  let excludedBaseTerms = [];
  let excludeTerms = [...queryExcludeTerms];
  if (queryExcludeTermsNewItem) {
    excludeTerms.unshift(queryExcludeTermsNewItem);
  }
  //always exclude
  excludedBaseTerms.push("not", "non");

  if (!newQuery.toLowerCase().includes("break") && queryExcludeBreaks) {
    excludedBaseTerms.push("break");
  }

  if (queryExcludeLots) {
    excludedBaseTerms.push("Lot", "x2", "x3");
  }
  if (queryExcludeSealed) {
    excludedBaseTerms.push("Box", "Pack", "Sealed", "Set", "Case");
  }
  //excludeTerms = excludeTerms.join(',');
  //group and minimize to the -() format to save space
  let excludeString = "";
  for (let i = 0; i < excludeTerms.length; i++) {
    if (excludeString.length + excludeTerms[i].length > 98) {
      newQuery += " -(" + excludeString.substr(0, excludeString.length - 1) + ")";
      excludeString = excludeTerms[i] + ",";
    } else {
      excludeString += excludeTerms[i] + ",";
    }
  }
  if (excludeString) {
    newQuery += " -(" + excludeString.substr(0, excludeString.length - 1) + ")";
  }
  // Always include the base terms in their own exclusion string
  newQuery += ` -(${excludedBaseTerms.join(",")})`;
  return newQuery;
};

// terms for or condition in ebay have to be like (term1,term2)
const parseArray = (queryTerm) => {
  if (Array.isArray(queryTerm) && queryTerm.length > 1) {
    return `(${queryTerm.join(",")})`;
  } else {
    return queryTerm;
  }
};

// ebay doesn't get good results for things like (Prizm Basketball)
const removeSportsFromSets = (sets) => {
  const removeSports = (set) => {
    if (!set) return;

    // @TODO is there a global for sports? There should be
    return set
      .replace(/basketball/gi, "")
      .replace(/baseball/gi, "")
      .replace(/boxing/gi, "")
      .replace(/football/gi, "")
      .replace(/golf/gi, "")
      .replace(/hockey/gi, "")
      .replace(/mma/gi, "")
      .replace(/soccer/gi, "")
      .replace(/tennis/gi, "")
      .trim();
  };
  if (Array.isArray(sets)) {
    return sets.map(removeSports);
  } else {
    return removeSports(sets);
  }
};

const sortGradesBySearchGradeOptions = (grades) => {
  let sorted = [];
  let lastOnes = [];

  searchGradeOptions.forEach((searchGradeOption) => {
    if (grades.includes(searchGradeOption.label)) {
      sorted.push(searchGradeOption.label);
    }
  });

  grades.forEach((grade) => {
    if (!searchGradeOptions.find((sgo) => sgo.label === grade)) {
      lastOnes.push(grade);
    }
  });

  return [...sorted, ...lastOnes];
};

const filterCardsBySeller = (cards, sellers) => {
  let filtered = [];
  for (const card of cards) {
    // Don't mutate, bruh
    const nextCard = Object.assign({}, card);
    const filteredCardResults = nextCard.card_results.filter((result) => {
      const { seller_name } = result;
      return !sellers.includes(seller_name);
    });
    nextCard.card_results = filteredCardResults;
    filtered.push(nextCard);
  }
  return filtered;
};

// @TODO refactor the following to be the same function
const filterCardsByExcludeBuyItNow = (cards, excludeBuyItNow) => {
  if (!excludeBuyItNow) return cards;
  let filtered = [];
  for (const card of cards) {
    const nextCard = Object.assign({}, card);
    const filteredCardResults = nextCard.card_results.filter(filterExcludeBuyItNow);
    nextCard.card_results = filteredCardResults;
    filtered.push(nextCard);
  }
  return filtered;
};

const filterCardsByExcludeAuction = (cards, excludeAuction) => {
  if (!excludeAuction) return cards;
  let filtered = [];
  for (const card of cards) {
    const nextCard = Object.assign({}, card);
    const filteredCardResults = nextCard.card_results.filter(filterExcludeAuctions);
    nextCard.card_results = filteredCardResults;
    filtered.push(nextCard);
  }
  return filtered;
};

const filterCardsByExcludeSaleWithMakeOffer = (cards, excludeSaleWithMakeOffer) => {
  if (!excludeSaleWithMakeOffer) return cards;
  let filtered = [];
  for (const card of cards) {
    const nextCard = Object.assign({}, card);
    const filteredCardResults = nextCard.card_results.filter(filterExcludeSaleWithMakeOffer);
    nextCard.card_results = filteredCardResults;
    filtered.push(nextCard);
  }
  return filtered;
};

const filterCardResultBySeller = (result, sellers) => {
  return !sellers.includes(result.seller_name);
};

const filterCardResultByPlatform = (result, platforms) => {
  return !platforms.includes(result.source);
};

const filterCardsNoFeedback = (cards, excludeNoFeedback) => {
  if (!excludeNoFeedback) {
    return cards;
  }
  let filtered = [];
  for (const card of cards) {
    const nextCard = Object.assign({}, card);
    const filteredCardResults = nextCard.card_results.filter((result) => {
      return isNoFeedbackRating(result.buyer_feedback_rating, excludeNoFeedback);
    });
    nextCard.card_results = filteredCardResults;
    filtered.push(nextCard);
  }
  return filtered;
};

const filterCardResultNoFeedback = (result, excludeNoFeedback) =>
  isNoFeedbackRating(result.buyer_feedback_rating, excludeNoFeedback);

const filterCardsLowFeedback = (cards, excludeLowFeedback) => {
  if (!excludeLowFeedback) {
    return cards;
  }
  let filtered = [];
  for (const card of cards) {
    const nextCard = Object.assign({}, card);
    const filteredCardResults = nextCard.card_results.filter((result) => {
      return isLowFeedbackRating(result.buyer_feedback_rating, excludeLowFeedback);
    });
    nextCard.card_results = filteredCardResults;
    filtered.push(nextCard);
  }
  return filtered;
};

const filterCardResultLowFeedback = (result, excludeLowFeedback) =>
  isLowFeedbackRating(result.buyer_feedback_rating, excludeLowFeedback);

const isNoFeedbackRating = (rating, excludeNoFeedback) => {
  // Short circuits when everything should be shown ie excludeNoFeedback === false
  // or if feedback hasn't been run for this particular auction
  return (
    !excludeNoFeedback || rating === "" || rating === null || typeof rating === "undefined" || Number(rating) !== 0
  );
};

const isLowFeedbackRating = (rating, excludeLowFeedback) => {
  // Short circuits when everything should be shown ie excludeLowFeedback === false
  // or if feedback hasn't been run for this particular auction
  return (
    !excludeLowFeedback ||
    rating === "" ||
    rating === null ||
    typeof rating === "undefined" ||
    rating === ERRORS.BUYER_UNAVAILABLE ||
    Number(rating) >= LOW_FEEDBACK_THRESHOLD
  );
};

const filterExcludeBuyItNow = (result) => {
  return result.auction_type !== "Fixed" && result.auction_type !== "Store";
};

const filterExcludeAuctions = (result) => {
  return result.auction_type !== "Auction";
};

const filterExcludeSaleWithMakeOffer = (result) => {
  return !(result.offer_price && result.offer_price < result.selling_price && result.offer_price_message);
};

const parseBuyerFeedbackRating = (rating) => {
  if (rating === ERRORS.BUYER_UNAVAILABLE) {
    return "Unavailable";
  } else {
    return rating;
  }
};

const isFavorited = (id, shallowFavorites, type) => {
  return shallowFavorites.some((el) => Number(id) === Number(el.foreign_id) && type === el.type);
};

const getShallowFavoriteId = (foreignId, shallowFavorites, type) => {
  const favorite = shallowFavorites.find((el) => Number(foreignId) === Number(el.foreign_id) && type === el.type);
  return favorite ? favorite.id : undefined;
};

const sortCardsByNameThenGrade = (a, b) => {
  let aValue;
  let bValue;
  let aGrade;
  let bGrade;
  // The legends passes a ~nightmare of a payload~ full name down instead of properties
  if (a.payload) {
    aValue = a.payload.name.replace(gradesRegExp, "");
    bValue = b.payload.name.replace(gradesRegExp, "");

    const aMatch = a.payload.name.match(gradesRegExp);
    const bMatch = a.payload.name.match(gradesRegExp);
    [aGrade] = aMatch || [null];
    [bGrade] = bMatch || [null];
  } else {
    aValue = generateCardTitleWOGrade(a);
    bValue = generateCardTitleWOGrade(b);
    aGrade = a.grade && a.grade.name;
    bGrade = b.grade && b.grade.name;
  }
  // Grades need to be sorted
  if (aValue === bValue) {
    if (gradesIndexMap[aGrade] < gradesIndexMap[bGrade]) {
      return -1;
    } else if (gradesIndexMap[aGrade] > gradesIndexMap[bGrade]) {
      return 1;
    } else {
      return 0;
    }
  } else {
    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  }
};

const filterAllAdditionalResultsOptions = (results, globalState) => {
  return results
    .filter((result) => filterCardResultBySeller(result, globalState.excludeSellersFromChartResults))
    .filter((result) => filterCardResultNoFeedback(result, globalState.excludeNoFeedback))
    .filter((result) => filterCardResultLowFeedback(result, globalState.excludeLowFeedback))
    .filter((result) => filterExcludeBuyItNow(result, globalState.excludeBuyItNow))
    .filter((result) => filterExcludeAuctions(result, globalState.excludeAuction))
    .filter((result) => filterExcludeSaleWithMakeOffer(result, globalState.excludeSaleWithMakeOffer));
};

const setQueryParams = (url, params = {}) => {
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        if (item !== "" && item !== undefined && item !== null) {
          url.searchParams.append(key, item);
        }
      });
    } else if (value !== "" && value !== undefined && value !== null) {
      url.searchParams.append(key, value);
    }
  });
};

const sciFilter = (inputValue, otherValue) => {
  if (!inputValue) return true;
  if (!otherValue) return false;

  const getUniqueSplitParts = (string) => {
    return string
      .toString()
      .toLowerCase()
      .split(" ")
      .reduce((acc, part) => acc.add(part.trim()), new Set());
  };

  const getExcludeTerms = (terms) => {
    const termsCopy = new Set(terms);
    const excludeTerms = Array.from(termsCopy).reduce((acc, term) => {
      if (term.startsWith("-")) {
        acc.add(term.split("-").join(""));
        termsCopy.delete(term);
      }

      return acc;
    }, new Set());

    return { terms: termsCopy, excludeTerms };
  };

  const { terms: inputParts, excludeTerms } = getExcludeTerms(getUniqueSplitParts(inputValue));
  const otherValueParts = getUniqueSplitParts(otherValue);

  for (const inputPart of inputParts.keys()) {
    if (otherValueParts.has(inputPart)) {
      continue;
    }

    let innerPass = false;

    for (const otherValuePart of otherValueParts.keys()) {
      if (otherValuePart.startsWith(inputPart)) {
        innerPass = true;
        break;
      }
    }

    if (!innerPass) {
      return false;
    }
  }

  for (const excludeTerm of excludeTerms.keys()) {
    let innerPass = false;

    for (const otherValuePart of otherValueParts.keys()) {
      if (otherValuePart.startsWith(excludeTerm)) {
        innerPass = true;
        break;
      }
    }

    if (innerPass) {
      return false;
    }
  }

  return true;
};

const mapEntitiesToSelect = (entities) =>
  entities.map(({ id, name }) => ({
    label: name,
    value: id.toString(),
  }));

const selectedPlayersCheck = (item, selectedPlayers = []) =>
  !selectedPlayers.length || selectedPlayers.some((p) => p.value === item.player_id.toString());

const filterItemsBySelectedPlayers = (items, selectedPlayers) => {
  return items.filter((item) => selectedPlayersCheck(item, selectedPlayers));
};

const updateOneCard = (allCards, updatedCard, updatedCardQuery) => {
  return allCards.map((card) => {
    if (card.id === updatedCard.id) {
      return {
        ...updatedCard,
        card_query: updatedCardQuery,
      };
    }
    return card;
  });
};

const addOneCard = (allCards, newCard, newCardQuery) => {
  return allCards.concat({
    ...newCard,
    card_query: newCardQuery,
  });
};

const filterOption = (input, option) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const hasSetUelSettings = (uel) =>
  uel &&
  uel.blacklist &&
  uel.whitelist &&
  uel.words_to_strip &&
  uel.grades_to_strip &&
  (uel.blacklist.length || uel.whitelist.length || uel.words_to_strip.length || uel.grades_to_strip.length);

const filterSetsByHasUelSettings = (selectedId, allSetsOriginal) => {
  return allSetsOriginal.filter(({ id, card_set_uel_setting }) => {
    return hasSetUelSettings(card_set_uel_setting) && selectedId !== id;
  });
};

const copyTextToClipboard = (text) => {
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};

/*
 * To make sure correct picture is represented to the user we have to add "updated" to prevent caching issues
 */
const pickMmImage = (entity, size = "M", addTimeSuffix = false) => {
  let suffix = `-${size}`;
  if (addTimeSuffix) {
    suffix += `?updated=${new Date(entity.updatedAt).getTime()}`;
  }

  if (entity.isCustomCard) {
    const { custom_photos } = entity;
    if (custom_photos?.length) {
      return `${custom_photos[0].image_url}${suffix}`;
    }
  }

  return entity.photo_id ? `${entity.photo.image_url}${suffix}` : entity.image_url;
};

const sortDateMultiple = (a, b, field = "date_purchased") => {
  // date_purchased can be null | string (date | 'Multiple')

  const sort = (a, b) => {
    if (a > b) {
      return 1;
    }

    if (a < b) {
      return -1;
    }

    return 0;
  };

  if (a[field] && b[field]) {
    const aDate = a[field].toLowerCase();
    const bDate = b[field].toLowerCase();

    if (aDate !== "multiple" && bDate == "multiple") {
      return sort(aDate, b.children[0][field]);
    }

    if (aDate === "multiple" && bDate !== "multiple") {
      return sort(a.children[0][field], bDate);
    }

    if (aDate === "multiple" && bDate === "multiple") {
      return sort(a.children[0][field], b.children[0][field]);
    }

    return sort(aDate, bDate);
  }

  if (a[field] && !b[field]) {
    return 1;
  }

  if (!a[field] && b[field]) {
    return -1;
  }

  return 0;
};

const ROTATION_ID = "711-53200-19255-0";
const getEbayAffiliateURLParams = (campaign, startWith = "&") =>
  `${startWith}mkevt=1&mkcid=1&mkrid=${ROTATION_ID}&campid=${campaign || process.env.NEXT_PUBLIC_CAMPAIGN_ID}`;
const EBAY_SEARCH_LINK = "https://www.ebay.com/sch/i.html?_nkw=";

const generatePartnerLinkFromAuctionId = (auctionId, source) => {
  if (source === "myslabs_jobs") {
    return `https://myslabs.com/slab/view/${auctionId.replace("myslabs-", "")}`;
  }
  return `https://www.ebay.com/itm/${auctionId}` + getEbayAffiliateURLParams(null, "?");
};

const generatePartnerLinkFromAuctionURL = (auctionUrl, campaignId = null) => {
  if (!auctionUrl) {
    return null;
  }

  const url = new URL(auctionUrl);
  const startWith = Array.from(url.searchParams.keys()).length > 0 ? "&" : "?";

  return auctionUrl + getEbayAffiliateURLParams(campaignId, startWith);
};

const generatePartnerLinkFromQuery = (campaign, query = "", type) => {
  let str = `${EBAY_SEARCH_LINK}${encodeURIComponent(query)}${getEbayAffiliateURLParams(campaign)}`;

  switch (type) {
    case "sold": {
      str += "&LH_Sold=1";
      break;
    }
    case "active": {
      str += "&LH_Auction=1&_sop=1";
      break;
    }
  }

  return str;
};

export const generateSetVariationName = (setVariation) => {
  const { variation, print_run } = setVariation;

  let str = variation.name;
  if (print_run) str += ` ${print_run}`;

  return str;
};

const joinStrings = (strings, separator = " ") => {
  return strings.filter((str) => str).join(separator);
};

const generateSetVariationsOptions = (setVariations) => {
  const uniq = uniqBy(setVariations, "label");
  const grouped = groupBy(setVariations, "label");

  Object.keys(grouped).forEach((label) => {
    grouped[label] = grouped[label].map(({ value }) => value);
  });

  return { uniq, grouped };
};

const getTermsSV = (searchContext, options) => {
  const payload = {
    players: searchContext.playersSelected.map((item) => item.value),
    setVariations: searchContext.getGroupedSVByVariationName(),
    sets: searchContext.setsSelected.map((item) => item.value),
    years: searchContext.yearsSelected.map((item) => item.value),
    grades: searchContext.gradesSelected.map((item) => item.value),
    sports: searchContext.sportsSelected.map((item) => item.value),
    setNameYears: searchContext.setNameYearsSelected.map((item) => item.value),
  };

  if (options.rcOnly) payload.rcOnly = true;

  return payload;
};

const getTermsSealedWaxes = (searchContext) => {
  const payload = {
    sports: searchContext.sportsSelected.map((item) => item.value),
    sets: searchContext.setsSelected.map((item) => item.value),
    years: searchContext.yearsSelected.map((item) => item.value),
    boxTypes: searchContext.boxTypesSelected.map((item) => item.value),
  };

  return payload;
};

const getRollingChartData = (chartData) => {
  let rolling_sales_total = 0;
  let rolling_profit = 0;
  return chartData.map((item) => {
    rolling_sales_total = rolling_sales_total + (item.daily_sales_total || 0);
    rolling_profit = rolling_profit + (item.daily_profit || 0);
    return {
      ...item,
      rolling_profit,
      rolling_sales_total,
    };
  });
};

const getValueFromPrintRun = (printRun) => {
  if (!printRun) return null;
  if (printRun.includes("varies")) return 10;
  if (printRun === "SP" || printRun === "(SP)") return 500;
  if (printRun === "SSP" || printRun === "(SSP)") return 50;
  if (printRun === "SSSP" || printRun === "(SSSP)") return 20;
  if (printRun === "Case Hit" || printRun === "(Case Hit)") return 20;
  if (printRun.includes("(Print Run:")) return Number(printRun.replace(/\D/g, ""));
  if (Number(printRun)) return Number(printRun);
  if (printRun.includes("/")) return Number(printRun.replace(/\D/g, ""));
  return null;
};

/*
  Get count of cards which don't have a preview
  (incoming data is sorted by sport and player) e.g. { sportId: { playerId: [ card ] } }
 */
const calcCardsWithoutImg = (data = []) => {
  return data.filter((el) => !el.photo_id).length;
};

/*
  Get count of cards which don't have a preview. Cards come is as individual cards assigned in groups.
 */
const calculateAdminCardsWithoutImage = (data = []) => {
  // Data comes in groups of arrays, so first step is to flatten those groups with a reduce, then filter out elements
  // from the arrays that don't have a photo ID and return the length of the resulting arrays. Afterwards, we compute
  // the sum of the array sums to get a total sum of elements in this grouping.
  // ex: data = [ 79: [ card_detail1: {}, card_detail2: {} ] ]
  return data
    .reduce((acc, val) => {
      return [...acc, ...Object.values(val)];
    }, [])
    .map((elArray) => elArray.filter((el) => !el.photo_id).length)
    .reduce((sum, val) => sum + val, 0);
};

const calcNestedItems = (data = {}) => {
  return Object.values(data).reduce((acc, curr) => {
    acc += curr.length;
    return acc;
  }, 0);
};

/*
  Convert different objects to query string format
 */
const objToQs = (object = {}) => {
  return Object.keys(object)
    .map((key) => key + "=" + object[key])
    .join("&");
};

/*
  Convert router.query object to allowed filters
 */
const queryToFilters = (data = {}) => {
  const allowedKeys = [
    // main filters
    "gradedCardsOnly",
    "rcOnly",
    "grades",
    "sets",
    "set_variations",
    "players",
    "sports",
    "years",
    "boxTypes",
    "minAvgSale",
    "maxAvgSale",
    "minSaleCount",
    "maxSaleCount",

    // additional parameters
    "rangeId",
    "collectionCategory",
  ];
  const result = {};

  for (const key in data) {
    if (!allowedKeys.includes(key)) {
      continue;
    }
    const val = data[key];
    if (val === "") {
      continue;
    }

    switch (key) {
      case "gradedCardsOnly":
      case "rcOnly":
        if (val === "true") {
          result[key] = true;
        } else if (val === "false") {
          result[key] = false;
        } else {
          result[key] = val;
        }
        break;
      case "grades":
      case "sets":
        if (Array.isArray(val)) {
          result[key] = val;
        } else {
          result[key] = val.split(",");
        }
        break;
      case "set_variations":
      case "players":
      case "sports":
      case "years":
      case "boxTypes":
        if (Array.isArray(val)) {
          result[key] = val.map((el) => Number(el));
        } else {
          result[key] = val.split(",").map((el) => Number(el));
        }
        break;
      case "minAvgSale":
      case "maxAvgSale":
      case "minSaleCount":
      case "maxSaleCount":
      case "collectionCategory":
        result[key] = Number(val);
        break;
      default:
        result[key] = val;
        break;
    }
  }

  return result;
};

/*
  Remove empty arrays and undefined, null, false values
  Convert large arrays to comma-separated values
 */
const filtersToQuery = (data = {}) => {
  const filters = { ...data };

  for (const key in filters) {
    if ((Array.isArray(filters[key]) && !filters[key].length) || filters[key] === undefined || filters[key] === null) {
      delete filters[key];
    }

    switch (key) {
      case "set_variations":
        if (Array.isArray(filters[key])) {
          filters[key] = filters[key].join(",");
        }
        break;
      default:
        break;
    }
  }

  return filters;
};

const getTextWidth = (text = "", font = "16px Ubuntu") => {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
};

const getPlatformLogo = (record, withSellerName = false) => {
  let className;
  let sellerName;
  switch (true) {
    case record.source === "myslabs_jobs":
      className = "myslabs";
      break;
    case record.source === "pwcc":
      className = "pwcc";
      break;
    case record.source === "goldin_auctions":
      className = "goldin";
      break;
    case (record.source === "ebay_jobs" || record.source === "goldin_auctions") &&
      record.seller_name === "Goldin Auctions":
      className = "goldin";
      break;
    case record.source === "ebay_jobs" && record.seller_name === "PWCC":
      className = "pwcc";
      break;
    case record.source === "ebay_jobs" && record.seller_name === "Memory Lane":
      className = "ml";
      break;
    case record.source === "ebay_jobs" && record.seller_name === "Robert Edward Auctions":
      className = "rea";
      break;
    case record.source === "ebay_jobs" && record.seller_name === "SCP Auctions":
      className = "scp";
      break;
    default:
      className = "ebay";

      if (withSellerName) {
        sellerName = <div style={{ width: "100%", textAlign: "center" }}>({record.seller_name})</div>;
      }
      break;
  }

  if (!sellerName) {
    return <div className={`platform-logo ${className}`} />;
  }

  return (
    <>
      <div className={`platform-logo ${className}`} />
      {sellerName}
    </>
  );
};

function uniqByWith(data, uniqKey, comparator) {
  return data.reduce((result, item) => {
    const index = result.findIndex((elt) => elt[uniqKey] === item[uniqKey]);
    if (index === -1) {
      result[result.length] = item;
      return result;
    }
    const comparisonResult = comparator(result[index], item);
    if (comparisonResult) {
      result[index] = item;
    }
    return result;
  }, []);
}

function cardSlug(playerName, sportName, setYear, setName, variationName, gradeName = "") {
  let seo_slug = `${playerName}-${sportName}/${setYear}-${setName}-${variationName}`
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/\&/g, "")
    .replace(/[^-a-z0-9\/_]+/g, "-")
    .replace(/----|---|--/g, "-");
  if (seo_slug.endsWith("-")) {
    seo_slug = seo_slug.slice(0, seo_slug.length - 1);
  }

  const cardGrade = gradeName.replace(/\s/g, "+");
  return `https://www.sportscardinvestor.com/cards/${seo_slug}?grade=${cardGrade}`;
}

export default {
  getCardName,
  getSealedWaxName,
  getPercentChanged,
  defaultCardSort,
  sortByFieldName,
  sortByFieldNameNested,
  sortByFieldNameNumeric,
  sortByFieldNameNumericReverse,
  getAuctionTypes,
  groupByKey,
  mergeData,
  sortResultData,
  getColorFromPalette,
  getRandomHexColor,
  getRandomWeightedHexColorByKey,
  getRandomWeightedHexColor,
  parseCards,
  formatDateWithTime,
  formatMoney,
  formatRange,
  isAuction,
  calculateResultType,
  unauthorizedRedirect,
  homeRedirect,
  handleApiError,
  expiredRedirect,
  getTerms,
  getTermsSV,
  constructQuery,
  sortGradesBySearchGradeOptions,
  filterCardsBySeller,
  filterCardResultBySeller,
  parseBuyerFeedbackRating,
  filterCardResultNoFeedback,
  filterCardResultLowFeedback,
  filterCardsNoFeedback,
  filterCardsLowFeedback,
  filterExcludeBuyItNow,
  filterExcludeAuctions,
  filterExcludeSaleWithMakeOffer,
  isFavorited,
  getShallowFavoriteId,
  sortCardsByNameThenGrade,
  filterCardsByExcludeBuyItNow,
  filterCardsByExcludeAuction,
  filterCardsByExcludeSaleWithMakeOffer,
  filterAllAdditionalResultsOptions,
  removeSportsFromSets,
  generateSetName,
  uniqByWith,
  cardSlug,
  setQueryParams,
  sciFilter,
  mapEntitiesToSelect,
  selectedPlayersCheck,
  filterItemsBySelectedPlayers,
  updateOneCard,
  addOneCard,
  filterOption,
  hasSetUelSettings,
  filterSetsByHasUelSettings,
  copyTextToClipboard,
  pickMmImage,
  sortDateMultiple,
  generatePartnerLinkFromAuctionURL,
  generatePartnerLinkFromQuery,
  generatePartnerLinkFromAuctionId,
  generateSetVariationName,
  generateSetVariationsOptions,
  joinStrings,
  getRollingChartData,
  getColorWithGradientFromPalette,
  formattedAuctionTypes,
  sortDateByFieldName,
  getTermsSealedWaxes,
  getValueFromPrintRun,
  calcCardsWithoutImg,
  calcNestedItems,
  objToQs,
  queryToFilters,
  formatDateWithTimeTZ,
  filtersToQuery,
  getTextWidth,
  getPlatformLogo,
  calculateAdminCardsWithoutImage,
};
