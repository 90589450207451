const searchGradeOptions = [
  {
    label: "PSA 10",
    value: '"PSA 10" -(BGS,BVG,RCR,SGC,BCCG,GMA,9.5,9,8.5,8,OC,PD,ST,OF,MK,MC)',
  },
  {
    label: "PSA 9",
    value: '"PSA 9" -(BGS,BVG,RCR,SGC,BCCG,GMA,8.5,8,OC,PD,ST,OF,MK,MC)',
  },
  {
    label: "PSA 8.5",
    value: '"PSA 8.5" -(BGS,BVG,RCR,SGC,BCCG,GMA,OC,PD,ST,OF,MK,MC)',
  },
  {
    label: "PSA 8",
    value: '"PSA 8" -(BGS,BVG,RCR,SGC,BCCG,GMA,OC,PD,ST,OF,MK,MC)',
  },
  {
    label: "PSA 7.5",
    value: '"PSA 7.5" -(BGS,BVG,RCR,SGC,BCCG,GMA,OC,PD,ST,OF,MK,MC)',
  },
  {
    label: "PSA 7",
    value: '"PSA 7" -(BGS,BVG,RCR,SGC,BCCG,GMA,OC,PD,ST,OF,MK,MC)',
  },
  {
    label: "PSA 6",
    value: '"PSA 6" -(BGS,BVG,RCR,SGC,BCCG,GMA,OC,PD,ST,OF,MK,MC)',
  },
  {
    label: "PSA 5",
    value: '"PSA 5" -(BGS,BVG,RCR,SGC,BCCG,GMA,OC,PD,ST,OF,MK,MC)',
  },
  {
    label: "PSA 4",
    value: '"PSA 4" -(BGS,BVG,RCR,SGC,BCCG,GMA,OC,PD,ST,OF,MK,MC)',
  },
  {
    label: "PSA 3",
    value: '"PSA 3" -(BGS,BVG,RCR,SGC,BCCG,GMA,OC,PD,ST,OF,MK,MC)',
  },
  {
    label: "PSA 2",
    value: '"PSA 2" -(sgc,bgs,bvg)',
  },
  {
    label: "PSA 1",
    value: '"PSA 1" -(sgc,bgs,bvg)',
  },
  {
    label: "BGS 10 Black Label",
    value: '"Black Label" BGS -(PSA,BVG,RCR,SGC,BCCG,GMA,9.5,9,8.5,8)',
  },
  {
    label: "BGS 10 Pristine",
    value: '"BGS 10" -(PSA,BVG,RCR,SGC,BCCG,GMA,9.5,9,8.5,8)',
  },
  {
    label: "BGS 9.5",
    value: '"BGS 9.5" -(PSA,BVG,RCR,SGC,BCCG,GMA,9,8.5,8)',
  },
  {
    label: "BGS 9",
    value: '"BGS 9" -(PSA,BVG,RCR,SGC,BCCG,GMA,8.5,8)',
  },
  {
    label: "BGS 8.5",
    value: '"BGS 8.5" -(PSA,BVG,RCR,SGC,BCCG,GMA,8)',
  },
  {
    label: "BGS 8",
    value: '"BGS 8" -(PSA,BVG,RCR,SGC,BCCG,GMA)',
  },
  {
    label: "BGS 7.5",
    value: '"BGS 7.5" -(PSA,BVG,RCR,SGC,BCCG,GMA)',
  },
  {
    label: "BGS 7",
    value: '"BGS 7" -(PSA,BVG,RCR,SGC,BCCG,GMA)',
  },
  {
    label: "BGS 6",
    value: '"BGS 6" -(PSA,BVG,RCR,SGC,BCCG,GMA)',
  },
  {
    label: "BGS 5",
    value: '"BGS 5" -(PSA,BVG,RCR,SGC,BCCG,GMA)',
  },
  {
    label: "BVG 10 Pristine",
    value: '"BVG 10" -(PSA,BGS,RCR,SGC,BCCG,GMA,9.5,9,8.5,8)',
  },
  {
    label: "BVG 9.5",
    value: '"BVG 9.5" -(PSA,BGS,RCR,SGC,BCCG,GMA,9,8.5,8)',
  },
  {
    label: "BVG 9",
    value: '"BVG 9" -(PSA,BGS,RCR,SGC,BCCG,GMA,8.5,8)',
  },
  {
    label: "BVG 8.5",
    value: '"BVG 8.5" -(PSA,BGS,RCR,SGC,BCCG,GMA,8)',
  },
  {
    label: "BVG 8",
    value: '"BVG 8" -(PSA,BGS,RCR,SGC,BCCG,GMA)',
  },
  {
    label: "BVG 7.5",
    value: '"BVG 7.5" -(PSA,BGS,RCR,SGC,BCCG,GMA)',
  },
  {
    label: "BVG 7",
    value: '"BVG 7" -(PSA,BGS,RCR,SGC,BCCG,GMA)',
  },
  {
    label: "BVG 6",
    value: '"BVG 6" -(PSA,BGS,RCR,SGC,BCCG,GMA)',
  },
  {
    label: "BVG 5",
    value: '"BVG 5" -(PSA,BGS,RCR,SGC,BCCG,GMA)',
  },
  {
    label: "BVG 4",
    value: '"BVG 4" -(psa,bgs,sgc)',
  },
  {
    label: "BVG 3",
    value: '"BVG 3" -(psa,bgs,sgc)',
  },
  {
    label: "BVG 2",
    value: '"BVG 2" -(psa,bgs,sgc)',
  },
  {
    label: "BVG 1",
    value: '"BVG 1" -(psa,bgs,sgc)',
  },
  {
    label: "SGC 10 Pristine",
    value: '"SGC 10" (PRI,PRISTINE) -(PSA,BGS,RCR,BVG,BCCG,GMA,9.5,9,8.5,8)',
  },
  {
    label: "SGC 10 Mint",
    value: '"SGC 10" (MT,MINT) -(PSA,BGS,RCR,BVG,BCCG,GMA,9.5,9,8.5,8)',
  },
  {
    label: "SGC 9.5",
    value: '"SGC 9.5" -(PSA,BGS,RCR,BVG,BCCG,GMA,9,8.5,8)',
  },
  {
    label: "SGC 9",
    value: '"SGC 9" -(PSA,BGS,RCR,BVG,BCCG,GMA,8.5,8)',
  },
  {
    label: "SGC 8.5",
    value: '"SGC 8.5" -(PSA,BGS,RCR,BVG,BCCG,GMA,8)',
  },
  {
    label: "SGC 8",
    value: '"SGC 8" -(PSA,BGS,RCR,BVG,BCCG,GMA)',
  },
  {
    label: "SGC 7.5",
    value: '"SGC 7.5" -(PSA,BGS,RCR,BVG,BCCG,GMA)',
  },
  {
    label: "SGC 7",
    value: '"SGC 7" -(PSA,BGS,RCR,BVG,BCCG,GMA)',
  },
  {
    label: "SGC 6",
    value: '"SGC 6" -(PSA,BGS,RCR,BVG,BCCG,GMA)',
  },
  {
    label: "SGC 5",
    value: '"SGC 5" -(PSA,BGS,RCR,BVG,BCCG,GMA)',
  },
  {
    label: "SGC 4",
    value: '"SGC 4"',
  },
  {
    label: "SGC 3",
    value: '"SGC 3"',
  },
  {
    label: "SGC 2",
    value: '"SGC 2"',
  },
  {
    label: "SGC 1",
    value: '"SGC 1"',
  },
  {
    label: "Raw",
    value: "-(PSA,BGS,BVG,RCR,SGC,BCCG,GMA,10,9.5,9,8.5,8)",
  },
];

const gradesRegExp = new RegExp(searchGradeOptions.map((option) => `(${option.label})`).join("|"), "g");
const gradesIndexMap = {};
for (let i = 0; i < searchGradeOptions.length; i++) {
  gradesIndexMap[searchGradeOptions[i].label] = i;
}

const sortBySearchGrades = (grades) => {
  const sorted = [];
  const lastOnes = [];
  if (grades.length === 0) {
    return grades;
  }

  searchGradeOptions.forEach((option) => {
    if (grades.includes(option.label)) {
      sorted.push(option.label);
    } else lastOnes.push(option.label);
  });

  grades.forEach((grade) => {
    if (!searchGradeOptions.some((sgo) => sgo.label === grade)) lastOnes.push(grade);
  });
  return [...sorted, ...lastOnes];
};

export { searchGradeOptions, sortBySearchGrades, gradesRegExp, gradesIndexMap };
