import { LineChartOutlined } from "@ant-design/icons";
import { Tooltip, Button } from "antd";
import { ENTITY_TYPES } from "utils/constants";

const ChartLink = ({
  record,
  cardId,
  queryRangeId,
  from,
  to,
  entityType = ENTITY_TYPES.CARD,
  isToMMx,
  style,
  ...props
}) => {
  let href = `/playercharts?cid=${cardId}`;
  if (isToMMx && cardId) {
    href = `https://marketmovers.sportscardinvestor.com/charts?sid=${cardId}&ct=${
      entityType === ENTITY_TYPES.CARD ? "sports-card" : "sealed-wax-card"
    }`;
    if (from || to) {
      href += `&from=${from}&to=${to}`;
    }
  } else {
    if (record?.isCustomCard) {
      if (cardId) {
        const stringfiedCardId = `${cardId}`;
        href = `/livesearch?cid=${stringfiedCardId.replace("custom_", "")}`;
      } else {
        href = `/livesearch?${record.query.replace(/^\?/, "")}`;
      }
    }
    if (entityType === ENTITY_TYPES.SEALED_WAX && cardId && !record?.isCustomCard) {
      href = `/wax-charts?swid=${cardId}`;
    } else if (record?.isSealedWax && record?.isCustomCard) {
      href = `/sealedwaxsearch?${record.query.replace(/^\?/, "")}`;
    }
    if (queryRangeId && queryRangeId !== "all") {
      href += `&range=${queryRangeId}`;
    } else if (from || to) {
      href += `&from=${from}&to=${to}`;
    }
  }

  return (
    <Tooltip
      title={`Chart This ${record?.isSealedWax || entityType === ENTITY_TYPES.SEALED_WAX ? "Wax" : "Card"}${
        isToMMx ? " in MMx" : ""
      }`}
    >
      <Button type="link" {...props} href={href} target="_blank" icon={<LineChartOutlined />} style={style} />
    </Tooltip>
  );
};

export { ChartLink };
