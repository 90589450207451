import fetch from "isomorphic-unfetch";
import ls from "local-storage";
import { ERRORS, STATUS_CODES } from "../globals";
import Helpers from "./helpers";

import getConfig from "next/config";
import moment from "moment";
import { ENTITY_TYPES } from "utils/constants";

const { publicRuntimeConfig } = getConfig();

const API_ROOT = publicRuntimeConfig.API_HOST + "/api";
const JOBS_ROOT = publicRuntimeConfig.JOBS_HOST + "/api";
const { S3_HOST } = publicRuntimeConfig;

const SCI_LOCALSTORAGE_TOKEN = "sci_token";
const SCI_LOCALSTORAGE_TOKEN_EXPIRES_AT = "sci_token_expires_at";
const SCI_LOCALSTORAGE_USER = "sci_user";
const SCI_REQUIRED_RELOGIN = "sci_requires_reloggin";

const checkToken = async () => {
  return ls.get(SCI_LOCALSTORAGE_TOKEN);
};
const checkTokenSync = () => {
  return ls.get(SCI_LOCALSTORAGE_TOKEN);
};
const setToken = async (value) => {
  return ls.set(SCI_LOCALSTORAGE_TOKEN, value);
};
const removeToken = () => {
  return ls.remove(SCI_LOCALSTORAGE_TOKEN);
};
export const setUser = (value) => {
  return ls.set(SCI_LOCALSTORAGE_USER, value);
};
export const getUser = () => {
  return ls.get(SCI_LOCALSTORAGE_USER);
};
export const removeUser = () => {
  return ls.remove(SCI_LOCALSTORAGE_USER);
};
export const setExpiresAt = (value) => {
  return ls.set(SCI_LOCALSTORAGE_TOKEN_EXPIRES_AT, value);
};
export const getExpiresAt = () => {
  return ls.get(SCI_LOCALSTORAGE_TOKEN_EXPIRES_AT);
};
export const removeExpiresAt = () => {
  return ls.remove(SCI_LOCALSTORAGE_TOKEN_EXPIRES_AT);
};
export const setDidReLogin = (value) => {
  return ls.set(SCI_REQUIRED_RELOGIN, value);
};
export const getDidReLogin = () => {
  return ls.get(SCI_REQUIRED_RELOGIN);
};

//services
const standardResponseCheck = async (response, isAdmin = false) => {
  if (response.status >= 400 && response.status < 600) {
    if (response.status === STATUS_CODES.UNAUTHORIZED) {
      Helpers.handleApiError(new Error(ERRORS.UNAUTHORIZED));
    }

    let detailedError;
    if (isAdmin) {
      try {
        detailedError = await response.json();
      } catch (e) {
        detailedError = {};
      }
    }

    const errorMessage = isAdmin
      ? detailedError.name || detailedError.message || response.message || response.statusText
      : response.message || response.statusText;
    return {
      error: errorMessage,
      status: response.status,
    };
  } else {
    return response;
  }
};

const updateEntityWithQueryResCheck = async (response) => {
  if (response.status >= 400 && response.status < 600) {
    if (response.status === STATUS_CODES.UNAUTHORIZED) {
      Helpers.handleApiError(new Error(ERRORS.UNAUTHORIZED));
    }

    let detailedError;
    try {
      detailedError = await response.json();
    } catch (e) {
      detailedError = {};
    }

    const errorMessage = detailedError.message || response.message || response.statusText;
    return {
      error: errorMessage,
      status: response.status,
    };
  } else {
    return response;
  }
};

const standardResponseCheckAsync = async (response) => {
  if (response.status >= 400 && response.status < 600) {
    const errorMessage = response.message || response.statusText;
    return {
      error: errorMessage,
      status: response.status,
      body: await response.json(),
    };
  } else {
    return response.json();
  }
};

export const ssoPassThrough = async (code) => {
  return await fetch(API_ROOT + "/users/sso", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      code,
    }),
  }).then(standardResponseCheckAsync);
};

export const login = async (email, password) => {
  const res = await fetch(API_ROOT + "/users/login", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

export const register = async (name, email, password) => {
  const res = await fetch(API_ROOT + "/users/register", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
      password,
    }),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

export const setFirstLogin = async () => {
  const res = await fetch(API_ROOT + "/users/firstLogin", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

export const getDashboard = async () => {
  const res = await fetch(API_ROOT + "/dashboard", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCard = async (cardId, params = {}, signal = null) => {
  const url = new URL(API_ROOT + `/cards/${cardId}`);

  Helpers.setQueryParams(url, params);
  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    signal,
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCards = async (params = {}) => {
  const url = new URL(`${API_ROOT}/cards`);

  Helpers.setQueryParams(url, params);
  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCardsMinimal = async (params = {}) => {
  const res = await fetch(`${API_ROOT}/cards/minimal`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(params),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const getCardsCsv = async (params = {}) => {
  const url = new URL(`${API_ROOT}/cards/csv`);

  Helpers.setQueryParams(url, params);
  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCardsBySetPicture = async ({
  onlyWithoutPicture,
  onlyWithSales,
  onlyWExcludedRank,
  showSnoozed,
  advancedFilters,
  limit,
  offset,
  useOpenSearch = false,
}) => {
  const {
    set_id: filterSet,
    player_id: filterPlayer,
    year: filterYear,
    variation_id: filterVariation,
    filter: filterTerm,
    sport_id: filterSport,
  } = advancedFilters;

  const params = {
    onlyWithoutPicture,
    onlyWithSales,
    onlyWExcludedRank,
    showSnoozed,
    filterSet,
    filterPlayer,
    filterYear,
    filterVariation,
    filterTerm,
    filterSport,
    limit,
    offset,
    useOS: useOpenSearch ?? false,
  };
  const url = new URL(`${API_ROOT}/cards/setPictures`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const listSealedWaxByPicture = async ({
  onlyWithoutPicture,
  onlyWithSales,
  onlyWExcludedRank,
  showSnoozed,
  limit,
  offset,
}) => {
  const params = { onlyWithoutPicture, onlyWithSales, onlyWExcludedRank, showSnoozed, limit, offset };
  const url = new URL(`${API_ROOT}/sealed-waxes/list-by-picture`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getPopularCards = async (searchTerm, params, signal) => {
  const url = new URL(`${API_ROOT}/cards/popular?queryTerms=${searchTerm ? encodeURIComponent(searchTerm) : ""}`);
  Helpers.setQueryParams(url, params);

  try {
    const res = await fetch(url, {
      signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await checkToken(),
      },
    }).then(standardResponseCheck);

    return res.error ? res : await res.json();
  } catch (error) {
    return null;
  }
};

const searchOpenIndex = async (searchTerm, params, signal) => {
  const url = new URL(`${API_ROOT}/opensearch/search?query=${searchTerm ? encodeURIComponent(searchTerm) : ""}`);
  Helpers.setQueryParams(url, params);

  try {
    const res = await fetch(url, {
      signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await checkToken(),
      },
    }).then(standardResponseCheck);

    return res.error ? res : await res.json();
  } catch (error) {
    return null;
  }
};

const getPopularSealedWaxes = async (searchTerm, params, signal) => {
  const url = new URL(
    `${API_ROOT}/sealed-waxes/popular?queryTerms=${searchTerm ? encodeURIComponent(searchTerm) : ""}`,
  );
  Helpers.setQueryParams(url, params);

  try {
    const res = await fetch(url, {
      signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await checkToken(),
      },
    }).then(standardResponseCheck);
    return res.error ? res : await res.json();
  } catch (error) {
    return null;
  }
};

const updateInclusion = async (auction_id, include_value, unsetUserReported, type = "CARD") => {
  const body = { included: include_value, status: include_value ? "ACTIVE" : "EXCLUDED_BY_ADMIN" };
  // Unset user-reported only if included
  if (unsetUserReported) {
    body.unset_flagged_by_user = true;
  }

  const res = await fetch(`${API_ROOT}/${type === "CARD" ? "cardResults" : "sealed-wax-results"}/${auction_id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const bulkUpdateInclusion = async (auction_ids, flag, setCardImages = false) => {
  const body = { auction_ids, flag, setCardImages };
  const res = await fetch(API_ROOT + "/cardResults/updateMany", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res;
};

const bulkUpdateInclusionWax = async (auction_ids, flag, setWaxImages = false) => {
  const body = { auction_ids, flag, setWaxImages };
  const res = await fetch(API_ROOT + "/sealed-wax-results/updateMany", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res;
};

const updateActiveInclusion = async (auction_id, include_value, unsetUserReported) => {
  const body = { included: include_value };
  // Unset user-reported only if included
  if (unsetUserReported) {
    body.unset_flagged_by_user = true;
  }
  const res = await fetch(API_ROOT + "/cardActiveResults?id=" + auction_id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const deleteActiveResult = async (auction_id) => {
  const res = await fetch(API_ROOT + "/cardActiveResults?id=" + auction_id, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCardResults = async (params = {}) => {
  const url = new URL(API_ROOT + "/cardresults");

  Helpers.setQueryParams(url, params);
  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getSealedWaxResults = async (params = {}) => {
  const url = new URL(API_ROOT + "/sealed-wax-results");

  Helpers.setQueryParams(url, params);
  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCardResultsBySet = async (setId, playerId, variationId, cardNumber, specificQualifier) => {
  const res = await fetch(
    API_ROOT +
      `/cardresults/listBySet/${encodeURIComponent(setId)}/${encodeURIComponent(playerId)}/${encodeURIComponent(
        variationId,
      )}/${encodeURIComponent(cardNumber) || null}/${encodeURIComponent(specificQualifier) || null}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await checkToken(),
      },
    },
  ).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getWaxResultsById = async (id) => {
  const res = await fetch(API_ROOT + `/sealed-wax-results/${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCardActiveResults = async (card_id, limit = "", skip = "", sortBy = "", orderBy = "") => {
  const params = { card_id, limit, skip, sortBy, orderBy };
  const url = new URL(`${API_ROOT}/cardactiveresults`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCardResultsExcluded = async (params = {}) => {
  const url = new URL(`${API_ROOT}/cardresults/excluded`); // + (card_id ? "?card_id=" + card_id : "");

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getWaxResultsExcluded = async (params = {}) => {
  const url = new URL(`${API_ROOT}/sealed-wax-results/excluded`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getLastSaleDate = async (cardId) => {
  const res = await fetch(API_ROOT + "/cardResults/" + cardId + "/lastSale", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getIncludedCardsCount = async (card_id) => {
  const url = API_ROOT + `/cardresults/${card_id}/countIncluded`;
  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCardResultsFlagged = async (card_id) => {
  const url = API_ROOT + "/cardresults/flagged" + (card_id ? "?card_id=" + card_id : "");
  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCardActiveResultsFlagged = async (card_id) => {
  const url = API_ROOT + "/cardactiveresults/flagged" + (card_id ? "?card_id=" + card_id : "");
  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCardsWithAutoExcluded = async () => {
  const url = API_ROOT + "/cards/excluded";
  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getExistingCardValues = async (record, card_field) => {
  const res = await fetch(API_ROOT + "/" + record + "/distinct?field=" + card_field, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getPlayerNotes = async (playerId) => {
  const res = await fetch(`${API_ROOT}/players/${playerId}/notes`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const createPlayerNote = async (playerId, playerNote) => {
  const res = await fetch(`${API_ROOT}/players/${playerId}/notes`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(playerNote),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const updatePlayerNote = async (playerId, playerNoteId, playerNote) => {
  const res = await fetch(`${API_ROOT}/players/${playerId}/notes/${playerNoteId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(playerNote),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const deletePlayerNote = async (playerId, playerNoteId) => {
  const res = await fetch(`${API_ROOT}/players/${playerId}/notes/${playerNoteId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : {};
};

const getPlayer = async (id) => {
  const res = await fetch(API_ROOT + "/players/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getPlayers = async (params = {}) => {
  const url = new URL(API_ROOT + "/players");

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};
const createPlayer = async (player) => {
  const res = await fetch(API_ROOT + "/players", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(player),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const deletePlayer = async (playerId) => {
  return await fetch(API_ROOT + "/players/" + playerId, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
};

const getSets = async () => {
  const res = await fetch(API_ROOT + "/sets", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createSet = async (set) => {
  const res = await fetch(API_ROOT + "/sets", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(set),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const deleteSet = async (setId) => {
  return await fetch(API_ROOT + "/sets/" + setId, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
};

const deleteVariation = async (variationId) => {
  return await fetch(API_ROOT + "/variations/" + variationId, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
};

const deleteSetVariation = async (setVariationId) => {
  return await fetch(API_ROOT + "/set-variations/" + setVariationId, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
};

const deleteCard = async (card) => {
  const res = await fetch(API_ROOT + "/cards?id=" + card.id, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(card),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const createCards = async (cards, params = {}) => {
  const url = new URL(`${API_ROOT}/cards`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(cards),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateCard = async (card, params = {}) => {
  const url = new URL(`${API_ROOT}/cards/${card.id}`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(card),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const seedCardData = async (cardId, numberOfDays, headers = {}) => {
  let url = API_ROOT + "/jobs/seed/" + cardId;
  if (numberOfDays) url += `?days=${numberOfDays}`;

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
      ...headers,
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runActiveFreqTierJob = async () => {
  const res = await fetch(API_ROOT + "/jobs/active-rules", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runBatchActiveFreqTierJob = async () => {
  const res = await fetch(API_ROOT + "/batch/active-rules", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runBatchPickMySlabsCardsJob = async () => {
  const res = await fetch(API_ROOT + "/batch/pick-myslabs-cards", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runBatchCleanCustomPhotosJob = async () => {
  const res = await fetch(API_ROOT + "/batch/clean-custom-photos", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runBatchGetBuyerFeedback = async () => {
  const res = await fetch(API_ROOT + "/batch/get-buyer-feedback", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const syncDatabaseWithProduction = async () => {
  return await fetch(API_ROOT + "/jobs/syncEnvironments", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheckAsync);
};

const searchCardResults = async (params) => {
  const res = await fetch(API_ROOT + "/cards/search", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(params),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const listEntitiesBySearchIds = async (searchString) => {
  const res = await fetch(API_ROOT + "/entities?" + searchString, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateEbayToken = async (params) => {
  const res = await fetch(API_ROOT + "/ebay/accesstoken?" + params, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  })
    .then(standardResponseCheck)
    .catch((ex) => {
      console.error(ex);
      return { error: ex.error };
    });
  return res.error ? res : await res.json();
};

const getEbayTokenLink = (ebay_credential_id) => {
  const query = ebay_credential_id ? "?ebayId=" + ebay_credential_id : "";
  return API_ROOT + "/ebay/oauth" + query;
};

const ebaySearch = async (query, start_date, end_date, test, headers = {}) => {
  const res = await fetch(API_ROOT + "/ebay/search", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
      ...headers,
    },
    body: JSON.stringify({
      query,
      start_date,
      end_date,
      test,
    }),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const alternativeEbaySearch = async (body, headers = {}) => {
  const res = await fetch(API_ROOT + "/ebay/alternativesearch", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
      ...headers,
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCardResultBestOffer = async (card_id) => {
  const res = await fetch(API_ROOT + "/cardResults/offer/" + card_id, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getWaxResultBestOffer = async (card_id) => {
  const res = await fetch(API_ROOT + "/sealed-wax-results/offer/" + card_id, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getBuyerFeedback = async (auction_id, entityType = "CARD") => {
  const url = `${API_ROOT}/ebay/buyer/${auction_id}?entityType=${encodeURIComponent(entityType)}`;

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const ebayGetBestOffer = async (auction_id, entityType = "CARD") => {
  const url = `${API_ROOT}/ebay/offer/${auction_id}?entityType=${entityType}`;

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getAllCardStats = async (params = {}) => {
  const res = await fetch(`${API_ROOT}/card/stats/all`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(params),
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const downloadCardStats = async (params) => {
  const url = new URL(`${API_ROOT}/card/stats/csv`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "text/csv",
      Authorization: await checkToken(),
    },
  });

  return !res.ok ? res.json() : res.text();
};

const downloadLastActiveCardsStats = async () => {
  const url = new URL(`${API_ROOT}/data-dashboard/fetch-cards-last-active-sync/csv`);

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "text/csv",
      Authorization: await checkToken(),
    },
  });

  return !res.ok ? res.json() : res.text();
};

const fetchDailyActiveAuctionsCounts = async () => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-daily-active-auctions-counts`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));

  return res.error ? res : await res.json();
};

const deleteResult = async (result_id) => {
  const res = await fetch(`${API_ROOT}/cardResults/${result_id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateCardStats = async (id) => {
  const url = new URL(`${API_ROOT}/jobs/stats`);

  Helpers.setQueryParams(url, { id });

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateWaxStatsSingle = async (id) => {
  const url = new URL(`${API_ROOT}/jobs/wax-stats-single`);

  Helpers.setQueryParams(url, { id });

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const checkPoolHealth = async () => {
  const res = await fetch(API_ROOT + "/ebay/available", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const isCalculationRunning = async () => {
  const res = await fetch(API_ROOT + "/jobs/running", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getJobsHistory = async () => {
  const res = await fetch(API_ROOT + "/jobs/history", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runGetBestOffersJob = async (auction_id = "", type = ENTITY_TYPES.CARD) => {
  if (type === ENTITY_TYPES.SEALED_WAX) {
    type = "WAX";
  }
  const url = `${API_ROOT}/jobs/offers?auction_id=${auction_id}&type=${type}`;

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runGetBestOffersBatch = async () => {
  const res = await fetch(JOBS_ROOT + "/batch/best-offers?serverId=1&serverCount=1", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runCalculateStatsBatch = async () => {
  const res = await fetch(JOBS_ROOT + "/batch/calculate-stats", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runComputeAlertsBatch = async (id) => {
  const res = await fetch(JOBS_ROOT + `/batch/compute-alerts/${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runBuyerInfoBatch = async (id, headers = {}) => {
  const res = await fetch(JOBS_ROOT + `/batch/buyer-info`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
      ...headers,
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runCollectionHistoryBatch = async () => {
  const res = await fetch(JOBS_ROOT + `/batch/collection-history`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runGetActiveAuctionsJob = async (numberOfCards = 10) => {
  const res = await fetch(API_ROOT + "/jobs/batchActive?limit=" + numberOfCards, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runBatch = async (serverId, serverCount, jobCardPicksJshId) => {
  let url = JOBS_ROOT + `/batch/completed-items?serverId=${serverId}&serverCount=${serverCount}`;
  if (jobCardPicksJshId) url += `&jobCardPicksJshId=${jobCardPicksJshId}`;

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runCompletedItemsSetsJob = async (serverId, serverCount, jobCardPicksJshId) => {
  let url = JOBS_ROOT + `/batch/completed-items-sets?serverId=${serverId}&serverCount=${serverCount}`;
  if (jobCardPicksJshId) url += `&jobCardPicksJshId=${jobCardPicksJshId}`;

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runActiveBatch = async (serverId, serverCount, jobCardPicksJshId) => {
  let url = JOBS_ROOT + `/batch/active-auctions?serverId=${serverId}&serverCount=${serverCount}`;
  if (jobCardPicksJshId) url += `&jobCardPicksJshId=${jobCardPicksJshId}`;

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runActiveSetsBatch = async (serverId, serverCount, jobCardPicksJshId) => {
  let url = JOBS_ROOT + `/batch/active-auctions-sets?serverId=${serverId}&serverCount=${serverCount}`;
  if (jobCardPicksJshId) url += `&jobCardPicksJshId=${jobCardPicksJshId}`;

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runPopCountBatch = async () => {
  const res = await fetch(`${JOBS_ROOT}/batch/pop-count`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runCleanUpLogsBatch = async () => {
  const res = await fetch(`${JOBS_ROOT}/batch/cleanup-logs`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runCleanUpTrashResultsBatch = async () => {
  const res = await fetch(`${JOBS_ROOT}/batch/cleanup-trash-results`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updatePopCountSingle = async (cardId) => {
  const res = await fetch(`${API_ROOT}/jobs/pop-count/${cardId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const runCalculateStatsJob = async () => {
  const res = await fetch(API_ROOT + "/jobs/stats", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const runCalculateStatsChangeJob = async (id = "") => {
  const res = await fetch(API_ROOT + `/jobs/statschange/${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCardFilters = async (params) => {
  const res = await fetch(API_ROOT + "/cards/filters", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(params),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const setCardImage = async (card_id, auction_id) => {
  const res = await fetch(API_ROOT + "/cards/image/" + card_id + "/" + auction_id, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const setSealedWaxImage = async (sealed_wax_id, auction_id) => {
  const res = await fetch(API_ROOT + "/sealed-wax/image/" + sealed_wax_id + "/" + auction_id, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getEbayCallCounts = async () => {
  const res = await fetch(API_ROOT + "/ebay/calls/", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const saveSavedSearch = async (search) => {
  const res = await fetch(API_ROOT + "/savedSearch", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(search),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getSavedSearches = async (type) => {
  const res = await fetch(API_ROOT + "/savedSearch/" + type, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateSavedSearch = async (id, body) => {
  const res = await fetch(API_ROOT + `/savedSearch?id=${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateManySavedSearches = async (searches) => {
  const res = await fetch(API_ROOT + `/savedSearch/bulk`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify({ searches }),
  }).then(standardResponseCheck);
  return res;
};

const deleteSavedSearch = async (id) => {
  const res = await fetch(API_ROOT + "/savedSearch/" + id, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : true;
};

const createFlaggedCard = async (body) => {
  const res = await fetch(API_ROOT + "/flaggedCards", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getFlaggedCards = async () => {
  const res = await fetch(API_ROOT + "/flaggedCards", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createFlaggedAuction = async (body) => {
  const res = await fetch(API_ROOT + "/flaggedAuctions", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const rankPlayers = async () => {
  const res = await fetch(API_ROOT + "/players/rankPlayers", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : "Created";
};

const createFavorite = async (favorite) => {
  const res = await fetch(API_ROOT + "/favorite", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(favorite),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getFavorites = async (type, full = false) => {
  const res = await fetch(API_ROOT + "/favorite/" + type + `?full=${full}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const deleteFavorite = async (favoriteIds) => {
  const res = await fetch(API_ROOT + "/favorite", {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify({ favorites: favoriteIds }),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateFavorite = async (id, body) => {
  const res = await fetch(`${API_ROOT}/favorites/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const updateManyFavorites = async (favorites) => {
  const res = await fetch(API_ROOT + `/favorite/bulk`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify({ favorites }),
  }).then(standardResponseCheck);
  return res;
};

const createFavoriteCategory = async (body) => {
  const res = await fetch(`${API_ROOT}/favorite-categories`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const getFavoriteCategories = async (type) => {
  const url = new URL(`${API_ROOT}/favorite-categories`);

  Helpers.setQueryParams(url, { type });

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const updateFavoriteCategory = async (id, body) => {
  const res = await fetch(`${API_ROOT}/favorite-categories/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const deleteFavoriteCategory = async (id) => {
  const res = await fetch(`${API_ROOT}/favorite-categories/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);

  return res.error ? res : {};
};

const getFavoritesByCategory = async (id, params) => {
  const url = new URL(`${API_ROOT}/favorite-categories/${id}/favorites`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const createPersonalCollectionItem = async (personalCollectionItem) => {
  const res = await fetch(API_ROOT + "/personalCollection", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(personalCollectionItem),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updatePersonalCollectionItem = async (id, payload) => {
  const res = await fetch(`${API_ROOT}/personalCollection/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(payload),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updatePersonalCollectionItemNote = async (id, payload) => {
  const res = await fetch(`${API_ROOT}/personalCollection/${id}/note`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(payload),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const markCollectionItemAsSold = async (saleItem) => {
  const res = await fetch(API_ROOT + "/personalCollection/" + saleItem.collectionId + "/sold", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(saleItem),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const editSoldCollectionItem = async (saleItem) => {
  const res = await fetch(API_ROOT + "/personalCollection/" + saleItem.collectionId + "/sold", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(saleItem),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getPersonalCollection = async () => {
  const res = await fetch(API_ROOT + "/personalCollection/mycards", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getPersonalCollectionSealedWaxes = async () => {
  const res = await fetch(API_ROOT + "/personalCollection/mySealedWaxes", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getPersonalCollectionCustomCards = async (onlyCards) => {
  const res = await fetch(API_ROOT + `/personalCollection/custom?onlyCards=${onlyCards}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getManualCardsToPopular = async (offset, limit) => {
  const res = await fetch(API_ROOT + `/personalCollection/matchManual?offset=${offset}&limit=${limit}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const convertManualCardsToPopular = async (data) => {
  const res = await fetch(API_ROOT + `/personalCollection/convertManual`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getPersonalCollectionSold = async () => {
  const res = await fetch(API_ROOT + "/personalCollection/mysoldcards", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getPersonalCollectionSoldSealedWaxes = async () => {
  const res = await fetch(API_ROOT + "/personalCollection/mySoldSealedWaxes", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getPersonalCollectionSoldCustom = async (onlyCards) => {
  const res = await fetch(API_ROOT + `/personalCollection/mysoldcustomcards?onlyCards=${onlyCards}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getPersonalCollectionCardList = async () => {
  const res = await fetch(API_ROOT + "/personalCollection/mycardids", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getPersonalCollectionSealedWaxList = async () => {
  const res = await fetch(API_ROOT + "/personalCollection/mySealedWaxIds", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const deletePersonalCollectionItem = async (collection_item_id) => {
  const res = await fetch(API_ROOT + "/personalCollection/" + collection_item_id, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getPersonalCollectionStats = async (filterId, onlyCards = true) => {
  const res = await fetch(
    API_ROOT + `/personalCollection/stats/${filterId === null ? "" : filterId}?onlyCards=${onlyCards}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await checkToken(),
      },
    },
  ).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createAlert = async (data) => {
  const res = await fetch(API_ROOT + "/alert", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getAlerts = async (type) => {
  if (type === ENTITY_TYPES.SEALED_WAX) {
    type = "WAX";
  }

  const res = await fetch(`${API_ROOT}/alert?type=${type}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const deleteAlert = async (id) => {
  const res = await fetch(API_ROOT + "/alert/" + id, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : true;
};

const updateAlert = async (data) => {
  const res = await fetch(API_ROOT + "/alert/" + data.id, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const activateAlert = async (id) => {
  const res = await fetch(API_ROOT + "/alert/activate/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const triggerAlertJob = async (userId) => {
  const res = await fetch(API_ROOT + "/jobs/alerts/" + userId, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createCustomSealedWax = async (data) => {
  const res = await fetch(API_ROOT + "/custom-sealed-wax", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createCustomCard = async (data) => {
  const res = await fetch(API_ROOT + "/custom-cards", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateCustomSealedWax = async (id, update) => {
  const res = await fetch(`${API_ROOT}/custom-sealed-wax/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(update),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCustomCard = async (id) => {
  const url = new URL(`${API_ROOT}/custom-cards/${id}`);

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
  return res.error ? {} : res.json();
};

const updateCustomCard = async (id, update) => {
  const res = await fetch(`${API_ROOT}/custom-cards/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(update),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const deleteCustomCard = async (customCardId) => {
  const res = await fetch(`${API_ROOT}/custom-cards/${customCardId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const deleteCustomSealedWax = async (id) => {
  const res = await fetch(`${API_ROOT}/custom-sealed-wax/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getErrorLogs = async () => {
  const res = await fetch(API_ROOT + "/apiErrorLog", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const markErrorsAsSolved = async (ids) => {
  const res = await fetch(API_ROOT + `/apiErrorLog/solved`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(ids),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createAwesomeSupportTicket = async (body) => {
  const res = await fetch(API_ROOT + `/awesome-support-tickets`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  });
  return res;
};

const createCollectionCategory = async (body) => {
  const res = await fetch(API_ROOT + `/collectionCategory`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  });
  return res.error ? res : await res.json();
};

const listCollectionCategories = async (onlyCards) => {
  const res = await fetch(API_ROOT + `/collectionCategory?&onlyCards=${onlyCards}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
  return res.error ? res : await res.json();
};

const associateCollectionCategory = async (personal_collection_id, collection_category_id) => {
  const res = await fetch(API_ROOT + `/collectionCategory/associate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify({ personal_collection_id, collection_category_id }),
  });
  return res.error ? res : await res.json();
};

const disassociateCollectionCategory = async (personal_collection_id) => {
  const res = await fetch(API_ROOT + `/collectionCategory/disassociate/${personal_collection_id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
  return res.error ? res : await res.json();
};

const updateCollectionCategory = async (id, name) => {
  const res = await fetch(API_ROOT + `/collectionCategory/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify({ name }),
  });
  return res.error ? res : await res.json();
};

const deleteCollectionCategory = async (id) => {
  const res = await fetch(API_ROOT + `/collectionCategory/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
  return res.error ? res : await res.json();
};

const ebayGetAuction = async (id) => {
  const res = await fetch(API_ROOT + `/ebay/auction/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
  return res.error ? res : await res.json();
};

const createCardResult = async (data) => {
  const res = await fetch(`${API_ROOT}/cardResults`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const bulkCreateResultsCSV = async (data) => {
  const res = await fetch(API_ROOT + `/cardResults/bulkCreateResultsCSV`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createSealedWaxResult = async (data) => {
  const res = await fetch(API_ROOT + `/sealed-wax-results`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  });
  return res.error ? res : await res.json();
};

const createCardResultRaw = async (data) => {
  const res = await fetch(`${API_ROOT}/cardResults/raw`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createWaxResultRaw = async (data) => {
  const res = await fetch(API_ROOT + `/sealed-wax-results/raw`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  });
  return res.error ? res : await res.json();
};

const getJobsLastDayStatus = async () => {
  const res = await fetch(API_ROOT + `/jobs/last-day-status`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
  return res.error ? res : await res.json();
};

const getJobs = async () => {
  const res = await fetch(API_ROOT + `/jobs`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
  return res.error ? res : await res.json();
};

const updateJob = async (id, data) => {
  const res = await fetch(API_ROOT + `/jobs/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  });
  return res.error ? res : await res.json();
};

const getJobStatusHistories = async (jobId) => {
  const res = await fetch(API_ROOT + `/jobs/${jobId}/job-status-histories`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
  return res.error ? res : await res.json();
};

const fetchCardPicksWp = async (filters) => {
  const res = await fetch(API_ROOT + `/reports/card-picks-wp`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(filters),
  });
  return res.error ? res : await res.json();
};

const fetchMasterReportData = async (params) => {
  const url = new URL(`${API_ROOT}/reports/master-card-extract`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });

  return res.json();
};

const downloadMasterReport = async (params) => {
  const url = new URL(`${API_ROOT}/reports/master-card-extract/csv`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "text/csv",
      Authorization: await checkToken(),
    },
  });

  return !res.ok ? res.json() : res.text();
};

const fetchPopCountReportData = async (params) => {
  const url = new URL(`${API_ROOT}/reports/pop-count`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });

  return res.json();
};

const fetchApiCallLoggingReportData = async () => {
  const url = new URL(`${API_ROOT}/reports/api-call-logging`);

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });

  return res.json();
};

const createGrade = async (body) => {
  const res = await fetch(API_ROOT + `/grades`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getGrades = async () => {
  const res = await fetch(API_ROOT + `/grades`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createVariation = async (body) => {
  const res = await fetch(API_ROOT + `/variations`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getVariations = async (excludeDeprecated = false) => {
  const res = await fetch(API_ROOT + `/variations?excludeDeprecated=${excludeDeprecated}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createSport = async (body) => {
  const res = await fetch(API_ROOT + `/sports`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const deleteSport = async (sportId) => {
  return fetch(`${API_ROOT}/sports/${sportId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
};

const getSports = async () => {
  const res = await fetch(API_ROOT + `/sports`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createGalaxy = async (body) => {
  const res = await fetch(API_ROOT + `/galaxies`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateGalaxy = async (id, body) => {
  const res = await fetch(API_ROOT + `/galaxies/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const deleteGalaxy = async (galaxyId) => {
  return fetch(`${API_ROOT}/galaxies/${galaxyId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
};

const getGalaxies = async () => {
  const res = await fetch(API_ROOT + `/galaxies`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createUniverse = async (body) => {
  const res = await fetch(API_ROOT + `/universes`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateUniverse = async (id, body) => {
  const res = await fetch(API_ROOT + `/universes/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const deleteUniverse = async (universeId) => {
  return fetch(`${API_ROOT}/universes/${universeId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
};

const getUniverses = async () => {
  const res = await fetch(API_ROOT + `/universes`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getPersonalCollectionHistory = async (type = ENTITY_TYPES.CARD) => {
  if (type === ENTITY_TYPES.SEALED_WAX) {
    type = "WAX";
  }

  const url = new URL(`${API_ROOT}/collection-history`);

  Helpers.setQueryParams(url, {
    type,
  });

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
  return res.error ? res : await res.json();
};

const createSetVariation = async (body) => {
  const res = await fetch(API_ROOT + `/set-variations`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  if (res.error) throw res;
  else return await res.json();
};

const getSetVariations = async () => {
  const res = await fetch(API_ROOT + `/set-variations`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getSetVariationsAssignedToCards = async () => {
  const res = await fetch(API_ROOT + `/set-variations/assigned`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createCardQuery = async (body) => {
  const res = await fetch(API_ROOT + `/card-queries`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateCardQuery = async (cardId, body) => {
  const res = await fetch(API_ROOT + `/card-queries/${cardId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getCardQuery = async (cardId) => {
  const res = await fetch(API_ROOT + `/card-queries/${cardId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updatePlayer = async (id, body) => {
  const res = await fetch(API_ROOT + `/players/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(updateEntityWithQueryResCheck);
  return res.error ? res : await res.json();
};

const updateCardSet = async (id, body, params) => {
  const url = new URL(`${API_ROOT}/sets/${id}`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(updateEntityWithQueryResCheck);
  return res.error ? res : await res.json();
};

const updateVariation = async (id, body) => {
  const res = await fetch(API_ROOT + `/variations/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateGrade = async (id, body) => {
  const res = await fetch(API_ROOT + `/grades/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(updateEntityWithQueryResCheck);
  return res.error ? res : await res.json();
};

const updateSetVariation = async (id, body, skipDuplicateCheck = false) => {
  let url = `${API_ROOT}/set-variations/${id}`;
  if (skipDuplicateCheck) url += "?skipDuplicateCheck=true";

  const res = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(updateEntityWithQueryResCheck);
  return res.error ? res : await res.json();
};

const updateSport = async (id, body) => {
  const res = await fetch(API_ROOT + `/sports/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createPhoto = async (setId, playerId, variationId, cardNumber, specificQualifier, formData) => {
  const res = await fetch(
    API_ROOT +
      `/admin/photos/${encodeURIComponent(setId)}/${encodeURIComponent(playerId)}/${encodeURIComponent(
        variationId,
      )}/${encodeURIComponent(cardNumber || null)}/${encodeURIComponent(specificQualifier || null)}`,
    {
      method: "POST",
      headers: {
        Authorization: await checkToken(),
      },
      body: formData,
    },
  );

  return res.error ? res : await res.json();
};

const createWaxPhoto = async (id, formData) => {
  const res = await fetch(API_ROOT + `/sealed-waxes/photo/${id}`, {
    method: "POST",
    headers: {
      Authorization: await checkToken(),
    },
    body: formData,
  });

  return res.error ? res : await res.json();
};

const getPhoto = async (params) => {
  const res = await fetch(API_ROOT + `/photos/get`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(params),
  });

  return res.error ? res : await res.json();
};

const createUelSetting = async (body) => {
  const res = await fetch(API_ROOT + `/uel-settings`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateUelSetting = async (id, body) => {
  const res = await fetch(API_ROOT + `/uel-settings/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getUelSettings = async () => {
  const res = await fetch(API_ROOT + `/uel-settings`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateCardSetUelSettings = async (id, body) => {
  const res = await fetch(API_ROOT + `/card-set-uel-settings/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(updateEntityWithQueryResCheck);
  return res.error ? res : await res.json();
};

const getGradeRatioAvailableFilters = async (filters = {}) => {
  const res = await fetch(API_ROOT + `/intelligence-reports/grade-ratios/filters`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(filters),
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const getGradeRatios = async (filters = {}, pagination = {}) => {
  const url = new URL(`${API_ROOT}/intelligence-reports/grade-ratios`);

  Helpers.setQueryParams(url, pagination);

  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(filters),
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const getVariationRatioAvailableFilters = async (filters = {}) => {
  const res = await fetch(API_ROOT + `/intelligence-reports/variation-ratios/filters`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(filters),
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const getVariationRatios = async (filters = {}, pagination = {}) => {
  const url = new URL(`${API_ROOT}/intelligence-reports/variation-ratios`);

  Helpers.setQueryParams(url, pagination);

  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(filters),
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const getPlayerRatioAvailableFilters = async (filters = {}) => {
  const res = await fetch(API_ROOT + `/intelligence-reports/player-ratios/filters`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(filters),
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const getPlayerRatios = async (filters = {}, pagination = {}) => {
  const url = new URL(`${API_ROOT}/intelligence-reports/player-ratios`);

  Helpers.setQueryParams(url, pagination);

  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(filters),
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const getLoggedInUser = async () => {
  const res = await fetch(API_ROOT + `/user`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const uploadEntityCsv = async (entityType, file, disableWarnings) => {
  let url = API_ROOT + `/admin/csv-import/${entityType}`;
  if (disableWarnings) url += `?disableWarnings=true`;
  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(file),
  }).then((res) => standardResponseCheck(res, true));

  if (res.error) throw res;
  return await res.json();
};

const uploadCardCsv = async (action, file, params = {}) => {
  let url = API_ROOT + `/admin/csv-import/cards/${action}`;
  const queryString = Helpers.objToQs(params);
  if (queryString) url += `?${queryString}`;

  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(file),
  }).then((res) => standardResponseCheck(res, true));

  if (res.error) throw res;
  return await res.json();
};

const createMasterSet = async (body) => {
  const res = await fetch(API_ROOT + `/job-master-sets`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const deleteMasterSet = async (id) => {
  const res = await fetch(`${API_ROOT}/job-master-sets/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const listMasterSets = async () => {
  const res = await fetch(API_ROOT + `/job-master-sets`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const bulkUpdateCardSetsMasterSetId = async (body) => {
  const res = await fetch(API_ROOT + `/sets/bulk-update-job-master-set-id`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateMasterSet = async (masterSetId, update) => {
  const res = await fetch(API_ROOT + `/job-master-sets/${masterSetId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(update),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const searchEbayDeal = async (params = {}) => {
  const url = new URL(API_ROOT + "/cards-for-sale/ebay-deal-search");

  Helpers.setQueryParams(url, params);
  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const searchEbayCardsUnderComps = async (params = {}) => {
  const url = new URL(API_ROOT + "/cards-for-sale/ebay-cards-under-comps-search");

  Helpers.setQueryParams(url, params);
  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const getRecentlyAddedCards = async (params = {}) => {
  const url = new URL(`${API_ROOT}/cards/recently-added`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const getCardsByAdminData = async (params = {}) => {
  const url = new URL(`${API_ROOT}/reports/cards-by-admin`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const getAdmins = async (params = {}) => {
  const url = new URL(`${API_ROOT}/users/admins`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const seedMultipleCards = async (cardIds, numberOfDays, headers = {}) => {
  let url = API_ROOT + "/jobs/seed-multiple";
  if (numberOfDays) url += `?days=${numberOfDays}`;

  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
      ...headers,
    },
    body: JSON.stringify({ cardIds }),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const seedSetData = async (setId, numberOfDays, headers = {}) => {
  let url = API_ROOT + "/jobs/seed-set-data";
  if (numberOfDays) url += `?days=${numberOfDays}`;

  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
      ...headers,
    },
    body: JSON.stringify({ setId }),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const seedWaxes = async (ids, numberOfDays, headers = {}) => {
  let url = API_ROOT + "/jobs/seed-waxes";
  if (numberOfDays) url += `?days=${numberOfDays}`;

  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
      ...headers,
    },
    body: JSON.stringify({ ids }),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createInfoBanner = async (banner) => {
  const res = await fetch(API_ROOT + "/info-banner", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(banner),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateInfoBanner = async (id, banner) => {
  const res = await fetch(API_ROOT + `/info-banner/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(banner),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const checkInfoBanner = async (clientUrl) => {
  const res = await fetch(API_ROOT + "/info-banner/check", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify({ clientUrl }),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const deleteInfoBanner = async (id) => {
  const res = await fetch(API_ROOT + `/info-banner/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getInfoBanners = async () => {
  const res = await fetch(API_ROOT + "/info-banner", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getAuctionLastCreatedAt = async () => {
  const res = await fetch(API_ROOT + "/cardResults/lastCreatedAt", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const parseInitialCollectionCsv = async (file) => {
  const res = await fetch(API_ROOT + `/personalCollection/massImportParse`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(file),
  }).then((res) => standardResponseCheck(res, true));

  if (res.error) throw res;
  return await res.json();
};

const validateInitialResultsCsv = async (file) => {
  const res = await fetch(API_ROOT + `/admin/csv-import/transactions/validate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(file),
  }).then((res) => standardResponseCheck(res, true));

  if (res.error) throw res;
  return await res.json();
};

const uploadCollectionCsv = async (data) => {
  const res = await fetch(API_ROOT + `/personalCollection/massImport`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  }).then((res) => standardResponseCheck(res, true));

  if (res.error) throw res;
  return await res.json();
};

const uploadTransactionCsv = async (data) => {
  const res = await fetch(API_ROOT + `/admin/csv-import/transactions/match`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  }).then((res) => standardResponseCheck(res, true));

  if (res.error) throw res;
  return await res.json();
};

const uploadQuestionCsv = async (table, file) => {
  const res = await fetch(API_ROOT + `/questions/${table}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(file),
  }).then((res) => standardResponseCheck(res, true));

  if (res.error) throw res;
  return await res.json();
};

const getQuestions = async (table) => {
  const res = await fetch(API_ROOT + `/questions/${table}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));

  if (res.error) throw res;
  return await res.json();
};

const collectionCreateMultiple = async (items) => {
  const res = await fetch(API_ROOT + `/personalCollection/multiple`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify({ items }),
  }).then((res) => standardResponseCheck(res, true));

  if (res.error) throw res;
  return await res.json();
};

const updateCardsSV = async (obj) => {
  const res = await fetch(API_ROOT + `/cards/bulk-update-set-variation`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(obj),
  }).then((res) => standardResponseCheck(res, true));

  if (res.error) throw res;
  return await res.json();
};

const getAvailableGradesForCard = async (cardId) => {
  const res = await fetch(API_ROOT + `/grades/available/${cardId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));

  if (res.error) throw res;
  return await res.json();
};

const convertCardsToGrades = async (originalCollectionId, gradedCards) => {
  const res = await fetch(API_ROOT + `/personalCollection/grade/${originalCollectionId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(gradedCards),
  }).then((res) => standardResponseCheck(res, true));

  if (res.error) throw res;
  return await res.json();
};

const getBoxType = async (boxId) => {
  const res = await fetch(`${API_ROOT}/box-types/${boxId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const getBoxTypes = async () => {
  const res = await fetch(`${API_ROOT}/box-types`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const createBoxType = async (payload) => {
  const res = await fetch(`${API_ROOT}/box-types`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(payload),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const updateBoxType = async (boxId, payload) => {
  const res = await fetch(`${API_ROOT}/box-types/${boxId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(payload),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const deleteBoxType = async (boxId) => {
  return fetch(`${API_ROOT}/box-types/${boxId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  });
};

const getWaxMasterSet = async (id) => {
  const res = await fetch(`${API_ROOT}/job-wax-master-sets/${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const getWaxMasterSets = async () => {
  const res = await fetch(`${API_ROOT}/job-wax-master-sets`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const createWaxMasterSet = async (payload) => {
  const res = await fetch(`${API_ROOT}/job-wax-master-sets`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(payload),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const updateWaxMasterSet = async (id, payload) => {
  const res = await fetch(`${API_ROOT}/job-wax-master-sets/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(payload),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const deleteWaxMasterSet = async (id) => {
  const res = await fetch(`${API_ROOT}/job-wax-master-sets/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : {};
};

const getSealedWax = async (id) => {
  const res = await fetch(`${API_ROOT}/sealed-waxes/${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const getSealedWaxes = async () => {
  const res = await fetch(`${API_ROOT}/sealed-waxes`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const createSealedWax = async (payload) => {
  const res = await fetch(`${API_ROOT}/sealed-waxes`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(payload),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const updateSealedWax = async (id, payload) => {
  const res = await fetch(`${API_ROOT}/sealed-waxes/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(payload),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const deleteSealedWax = async (id) => {
  const res = await fetch(`${API_ROOT}/sealed-waxes/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : {};
};

const getSCIAppIndex = async () => {
  const res = await fetch(`${S3_HOST}/card_index_full.json?timestamp=${moment().unix()}`, {
    method: "GET",
  });
  if (!res.ok) {
    return { error: "HTTP error " + res.status };
  }
  return res.json();
};

const getSCIAppFilters = async () => {
  const res = await fetch(`${S3_HOST}/filters.json`, {
    method: "GET",
  });
  if (!res.ok) {
    return { error: "HTTP error " + res.status };
  }
  return res.json();
};

const excludeFromAppRanking = async (excludedCombos, skipJob = false) => {
  const res = await fetch(`${API_ROOT}/gradeless-card-settings/exclude-from-ranking`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify({ excludedCombos, skipJob }),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : {};
};

const getRankExcludedStatus = async (params) => {
  const res = await fetch(`${API_ROOT}/gradeless-card-settings/exclude-from-ranking/get`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(params),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const getReportedWaxes = async () => {
  const res = await fetch(`${API_ROOT}/sealed-wax-results/flagged`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateWaxResult = async (id, payload = {}) => {
  const res = await fetch(`${API_ROOT}/sealed-wax-results/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(payload),
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const deleteWaxResult = async (id) => {
  const res = await fetch(`${API_ROOT}/sealed-wax-results/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const getReportedWaxAuctions = async () => {
  const res = await fetch(`${API_ROOT}/sealed-wax-active-results/flagged`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updateWaxAuctionResult = async (id, payload = {}) => {
  const res = await fetch(`${API_ROOT}/sealed-wax-active-results/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(payload),
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const deleteWaxAuctionResult = async (id) => {
  const res = await fetch(`${API_ROOT}/sealed-wax-active-results/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const fetchTotalResults = async () => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-total-results`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchBestOfferResults = async () => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-best-offer-results`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchAssignedResults = async () => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-assigned-results`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchResultsByCallType = async (type) => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-results-by-call-type/${type ? type : ""}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchCallsByCallType = async ({ type, source = "eBay" }) => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-calls-by-call-type/${source}/${type ? type : ""}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchDeletedResults = async () => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-deleted-results`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchExcludedResults = async () => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-excluded-results`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchCallsUsedToday = async () => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-calls-used-today`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const checkAuctionStatus = async (searchValue) => {
  const res = await fetch(`${API_ROOT}/data-dashboard/check-auction-status?auction_id=${searchValue}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchBestOfferUnexpectedErrors = async () => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-best-offer-unexpected-errors`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const retryBestOfferUnexpectedErrors = async () => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-best-offer-unexpected-errors`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchAuctionsWithoutStatus = async () => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-auctions-without-status`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const syncAuctionsWithoutStatus = async () => {
  const res = await fetch(`${API_ROOT}/data-dashboard/sync-auctions-without-status`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchCardsLastActiveSync = async () => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-cards-last-active-sync`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchAuctionsNullBuyerFeedback = async () => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-auction-null-buyer-feedback`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchSciAppLastSyncedAt = async () => {
  const res = await fetch(`${API_ROOT}/data-dashboard/fetch-sci-app-synced-at`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const deletePhoto = async (setId, playerId, variationId, cardNumber, specificQualifier) => {
  const res = await fetch(
    `${API_ROOT}/photos/${encodeURIComponent(setId)}/${encodeURIComponent(playerId)}/${encodeURIComponent(
      variationId,
    )}/${encodeURIComponent(cardNumber || null)}/${encodeURIComponent(specificQualifier || null)}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await checkToken(),
      },
    },
  ).then((res) => standardResponseCheck(res, true));
  return res.error ? res : {};
};

const deleteWaxPhoto = async (id) => {
  const res = await fetch(`${API_ROOT}/sealed-waxes/photo/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : {};
};

const createAdminPhoto = async (cardId, formData) => {
  const res = await fetch(`${API_ROOT}/cards/admin-photo/${cardId}`, {
    method: "POST",
    headers: {
      Authorization: await checkToken(),
    },
    body: formData,
  });

  return res.error ? res : await res.json();
};

const deleteAdminPhoto = async (cardId) => {
  const res = await fetch(`${API_ROOT}/cards/admin-photo/${cardId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : {};
};

const getSetMasterCardCount = async (id) => {
  const res = await fetch(`${API_ROOT}/sets/master-card-count/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchActiveFreqRules = async ({ includeUnlisted = false }) => {
  let url = `${API_ROOT}/active-freq-rules`;
  if (includeUnlisted) {
    url += "?includeUnlisted=true";
  }

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchActiveFreqStats = async () => {
  const res = await fetch(`${API_ROOT}/active-freq-rules/stats`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const createActiveFreqRule = async (data) => {
  const res = await fetch(`${API_ROOT}/active-freq-rules`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const updateActiveFreqRule = async (data) => {
  const res = await fetch(`${API_ROOT}/active-freq-rules/${data.id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(data),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const deleteActiveFreqRule = async (id) => {
  const res = await fetch(`${API_ROOT}/active-freq-rules/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const getSealedWaxSportsAssigned = async () => {
  const res = await fetch(`${API_ROOT}/sealed-waxes/sports`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const searchSealedWaxResults = async (params) => {
  const res = await fetch(API_ROOT + "/sealed-waxes/search", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(params),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getSealedWaxFilters = async (params) => {
  const res = await fetch(API_ROOT + "/sealed-waxes/filters", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(params),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getSealedWaxActiveResults = async (sealed_wax_id, limit = "", skip = "", sortBy = "", orderBy = "") => {
  const params = { sealed_wax_id, limit, skip, sortBy, orderBy };
  const url = new URL(`${API_ROOT}/sealed-wax-active-results`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getSealedWaxStats = async (params = {}) => {
  const res = await fetch(`${API_ROOT}/sealed-waxes/stats/all`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(params),
  }).then(standardResponseCheck);

  return res.error ? res : await res.json();
};

const downloadSealedWaxStats = async (params) => {
  const url = new URL(`${API_ROOT}/sealed-waxes/stats/csv`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "text/csv",
      Authorization: await checkToken(),
    },
  });

  return !res.ok ? res.json() : res.text();
};

const calculateStatsForCardLambda = async (ids) => {
  const res = await fetch(API_ROOT + "/cards/calculate-stats", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify({ ids }),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const calculateStatsForWaxLambda = async (ids) => {
  const res = await fetch(API_ROOT + "/sealed-waxes/calculate-stats", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify({ ids }),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getPopCountChartData = async (cardId, startDate, endDate) => {
  const params = { cardId, startDate, endDate };
  const url = new URL(`${API_ROOT}/daily-pop-count/chart`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const getOneCardMinimal = async (id) => {
  const res = await fetch(`${API_ROOT}/cards/minimal/${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const updatePhotoSnooze = async (params) => {
  const res = await fetch(`${API_ROOT}/gradeless-card-settings/snooze`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(params),
  }).then(standardResponseCheck);
  return res.error ? res : await res.json();
};

const createMarketIndex = async (payload) => {
  const res = await fetch(`${API_ROOT}/market-index`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(payload),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchMarketIndices = async () => {
  const res = await fetch(`${API_ROOT}/market-index`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchMarketIndicesPublic = async () => {
  const res = await fetch(`${API_ROOT}/market-index/public`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const fetchMarketIndicesMembers = async () => {
  const res = await fetch(`${API_ROOT}/market-index/members`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const getIndexDetails = async (id) => {
  const res = await fetch(`${API_ROOT}/market-index/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const getIndexCards = async (is_public = false, id, from, to, limit, page, sortBy, orderBy) => {
  const res = await fetch(
    `${API_ROOT}/market-index/${id}/cards${
      is_public ? "/public" : ""
    }?from=${from}&to=${to}&limit=${limit}&page=${page}&sortBy=${sortBy ? sortBy : null}&orderBy=${
      orderBy ? orderBy : null
    }`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await checkToken(),
      },
    },
  ).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const getIndexStats = async (is_public = false, id, from, to) => {
  const res = await fetch(`${API_ROOT}/market-index/${id}/stats${is_public ? "/public" : ""}?from=${from}&to=${to}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const deleteMarketIndex = async (id) => {
  const res = await fetch(`${API_ROOT}/market-index/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const updateMarketIndex = async (id, details, cardIds) => {
  const res = await fetch(`${API_ROOT}/market-index/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify({ indexDetails: details, indexCardIds: cardIds }),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const runPickEntitiesLambda = async (body) => {
  const res = await fetch(`${API_ROOT}/lambda-jobs/pick-entities`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const runFindCompletedItemsLambda = async (body) => {
  const res = await fetch(`${API_ROOT}/lambda-jobs/find-completed-items`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const runEntityMatcherLambda = async (body) => {
  const res = await fetch(`${API_ROOT}/lambda-jobs/entity-matcher`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const runCardLambda = async (body) => {
  const res = await fetch(`${API_ROOT}/lambda-jobs/card-lambda`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const runWaxLambda = async (body) => {
  const res = await fetch(`${API_ROOT}/lambda-jobs/wax-lambda`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const debugSingleQueryMatch = async (body) => {
  const res = await fetch(`${API_ROOT}/cards/debug-single-query-match`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(body),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const setMarketIndexOrder = async (indices) => {
  const res = await fetch(`${API_ROOT}/market-index/order`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify(indices.map((index) => index.id)),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const syncOpenSearchIndex = async (cardIds = []) => {
  const res = await fetch(`${API_ROOT}/batch/index-open-search`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
    body: JSON.stringify({ cardIds }),
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const getUserStats = async (searchParams) => {
  const url = new URL(`${API_ROOT}/user-stats`);
  const search = new URLSearchParams();
  Object.entries(searchParams).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((entry) => search.append(key, entry));
    } else {
      search.append(key, value);
    }
  });
  url.search = search;

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const runActiveMembersReports = async () => {
  const url = new URL(`${API_ROOT}/active-members-reporting`);

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

const runCustomActiveMembersReport = async (dateRange, membershipType) => {
  const params = { dateRange, membershipType };
  const url = new URL(`${API_ROOT}/custom-active-members-report`);

  Helpers.setQueryParams(url, params);

  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: await checkToken(),
    },
  }).then((res) => standardResponseCheck(res, true));
  return res.error ? res : await res.json();
};

export default {
  deleteCard,
  createCards,
  updateCard,
  setCardImage,
  searchCardResults,
  getCardFilters,
  seedCardData,
  getAllCardStats,
  downloadCardStats,
  downloadLastActiveCardsStats,
  checkToken,
  checkTokenSync,
  setToken,
  removeToken,
  setExpiresAt,
  getExpiresAt,
  removeExpiresAt,
  setDidReLogin,
  getDidReLogin,
  setUser,
  getUser,
  removeUser,
  login,
  register,
  getDashboard,
  getCard,
  getCards,
  getCardsMinimal,
  getCardsBySetPicture,
  getPopularCards,
  searchOpenIndex,
  getCardsWithAutoExcluded,
  getCardResults,
  getCardResultsBySet,
  getCardResultsExcluded,
  getLastSaleDate,
  getCardResultsFlagged,
  deleteResult,
  updateInclusion,
  updateActiveInclusion,
  getCardActiveResults,
  getCardActiveResultsFlagged,
  deleteActiveResult,
  getExistingCardValues,
  getSets,
  deleteSet,
  createSet,
  getPlayerNotes,
  createPlayerNote,
  updatePlayerNote,
  deletePlayerNote,
  getPlayer,
  getPlayers,
  deletePlayer,
  createPlayer,
  updateEbayToken,
  getEbayTokenLink,
  ebayGetAuction,
  updateCardStats,
  checkPoolHealth,
  getEbayCallCounts,
  getJobsHistory,
  isCalculationRunning,
  runGetBestOffersJob,
  runCalculateStatsJob,
  runCalculateStatsChangeJob,
  runGetActiveAuctionsJob,
  ssoPassThrough,
  ebaySearch,
  ebayGetBestOffer,
  getCardResultBestOffer,
  API_ROOT,
  saveSavedSearch,
  getSavedSearches,
  updateSavedSearch,
  updateManySavedSearches,
  deleteSavedSearch,
  createFlaggedCard,
  createFlaggedAuction,
  rankPlayers,
  getBuyerFeedback,
  bulkUpdateInclusion,
  getFlaggedCards,
  createFavorite,
  getFavorites,
  deleteFavorite,
  updateFavorite,
  updateManyFavorites,
  createPersonalCollectionItem,
  updatePersonalCollectionItem,
  updatePersonalCollectionItemNote,
  getPersonalCollection,
  deletePersonalCollectionItem,
  getPersonalCollectionCardList,
  getIncludedCardsCount,
  createAlert,
  getAlerts,
  deleteAlert,
  updateAlert,
  activateAlert,
  triggerAlertJob,
  getPersonalCollectionSold,
  markCollectionItemAsSold,
  getPersonalCollectionStats,
  editSoldCollectionItem,
  getCustomCard,
  createCustomCard,
  updateCustomCard,
  updateCustomSealedWax,
  deleteCustomCard,
  deleteCustomSealedWax,
  getPersonalCollectionCustomCards,
  getPersonalCollectionSoldCustom,
  runBatch,
  runActiveBatch,
  runGetBestOffersBatch,
  runCalculateStatsBatch,
  runComputeAlertsBatch,
  runBuyerInfoBatch,
  runCollectionHistoryBatch,
  getErrorLogs,
  markErrorsAsSolved,
  createSport,
  deleteSport,
  getSports,
  createGalaxy,
  updateGalaxy,
  deleteGalaxy,
  getGalaxies,
  createUniverse,
  updateUniverse,
  deleteUniverse,
  getUniverses,
  createGrade,
  getGrades,
  createVariation,
  getVariations,
  listEntitiesBySearchIds,
  createSetVariation,
  getSetVariations,
  createCardQuery,
  updateCardQuery,
  getCardQuery,
  updatePlayer,
  updateCardSet,
  updateVariation,
  updateGrade,
  updateSetVariation,
  updateSport,
  createAwesomeSupportTicket,
  createCollectionCategory,
  listCollectionCategories,
  associateCollectionCategory,
  disassociateCollectionCategory,
  updateCollectionCategory,
  deleteCollectionCategory,
  createCardResult,
  createCardResultRaw,
  createWaxResultRaw,
  fetchCardPicksWp,
  getJobsLastDayStatus,
  getJobs,
  getJobStatusHistories,
  fetchMasterReportData,
  downloadMasterReport,
  getPersonalCollectionHistory,
  fetchPopCountReportData,
  runCompletedItemsSetsJob,
  fetchApiCallLoggingReportData,
  alternativeEbaySearch,
  syncDatabaseWithProduction,
  createUelSetting,
  updateUelSetting,
  getUelSettings,
  createPhoto,
  getPhoto,
  updateCardSetUelSettings,
  runPopCountBatch,
  getGradeRatioAvailableFilters,
  getGradeRatios,
  getVariationRatioAvailableFilters,
  getVariationRatios,
  getPlayerRatioAvailableFilters,
  getPlayerRatios,
  updatePopCountSingle,
  getLoggedInUser,
  createCustomSealedWax,
  uploadEntityCsv,
  createMasterSet,
  deleteMasterSet,
  bulkUpdateCardSetsMasterSetId,
  listMasterSets,
  updateMasterSet,
  searchEbayDeal,
  searchEbayCardsUnderComps,
  getRecentlyAddedCards,
  seedMultipleCards,
  seedSetData,
  createInfoBanner,
  getInfoBanners,
  updateInfoBanner,
  checkInfoBanner,
  deleteInfoBanner,
  deleteVariation,
  deleteSetVariation,
  uploadCollectionCsv,
  collectionCreateMultiple,
  getAuctionLastCreatedAt,
  runCleanUpLogsBatch,
  getCardsByAdminData,
  getAdmins,
  updateJob,
  runActiveSetsBatch,
  uploadQuestionCsv,
  getQuestions,
  uploadCardCsv,
  getCardsCsv,
  getManualCardsToPopular,
  convertManualCardsToPopular,
  updateCardsSV,
  getSetVariationsAssignedToCards,
  getBoxType,
  getBoxTypes,
  createBoxType,
  updateBoxType,
  deleteBoxType,
  getWaxMasterSet,
  getWaxMasterSets,
  createWaxMasterSet,
  updateWaxMasterSet,
  deleteWaxMasterSet,
  getSealedWax,
  getSealedWaxes,
  createSealedWax,
  updateSealedWax,
  deleteSealedWax,
  createFavoriteCategory,
  getFavoriteCategories,
  updateFavoriteCategory,
  deleteFavoriteCategory,
  getFavoritesByCategory,
  parseInitialCollectionCsv,
  getAvailableGradesForCard,
  convertCardsToGrades,
  runCleanUpTrashResultsBatch,
  getSCIAppIndex,
  getSCIAppFilters,
  excludeFromAppRanking,
  getRankExcludedStatus,
  getReportedWaxes,
  updateWaxResult,
  deleteWaxResult,
  getReportedWaxAuctions,
  updateWaxAuctionResult,
  deleteWaxAuctionResult,
  fetchTotalResults,
  fetchBestOfferResults,
  fetchAssignedResults,
  fetchResultsByCallType,
  fetchCallsByCallType,
  fetchDeletedResults,
  fetchExcludedResults,
  fetchCallsUsedToday,
  checkAuctionStatus,
  fetchBestOfferUnexpectedErrors,
  retryBestOfferUnexpectedErrors,
  fetchAuctionsWithoutStatus,
  syncAuctionsWithoutStatus,
  fetchCardsLastActiveSync,
  fetchAuctionsNullBuyerFeedback,
  fetchSciAppLastSyncedAt,
  getWaxResultsExcluded,
  bulkUpdateInclusionWax,
  getWaxResultBestOffer,
  setSealedWaxImage,
  seedWaxes,
  deletePhoto,
  createAdminPhoto,
  deleteAdminPhoto,
  getSetMasterCardCount,
  fetchActiveFreqRules,
  fetchActiveFreqStats,
  createActiveFreqRule,
  updateActiveFreqRule,
  deleteActiveFreqRule,
  runActiveFreqTierJob,
  getSealedWaxSportsAssigned,
  searchSealedWaxResults,
  getSealedWaxFilters,
  getSealedWaxActiveResults,
  getSealedWaxStats,
  downloadSealedWaxStats,
  getSealedWaxResults,
  getPersonalCollectionSealedWaxes,
  getPersonalCollectionSoldSealedWaxes,
  getPersonalCollectionSealedWaxList,
  getPopularSealedWaxes,
  calculateStatsForCardLambda,
  calculateStatsForWaxLambda,
  runBatchActiveFreqTierJob,
  runBatchPickMySlabsCardsJob,
  runBatchCleanCustomPhotosJob,
  runBatchGetBuyerFeedback,
  listSealedWaxByPicture,
  createWaxPhoto,
  deleteWaxPhoto,
  getWaxResultsById,
  createSealedWaxResult,
  updateWaxStatsSingle,
  validateInitialResultsCsv,
  uploadTransactionCsv,
  bulkCreateResultsCSV,
  getPopCountChartData,
  getOneCardMinimal,
  updatePhotoSnooze,
  createMarketIndex,
  fetchMarketIndices,
  fetchMarketIndicesPublic,
  fetchMarketIndicesMembers,
  getIndexDetails,
  getIndexCards,
  getIndexStats,
  deleteMarketIndex,
  updateMarketIndex,
  runPickEntitiesLambda,
  runFindCompletedItemsLambda,
  runEntityMatcherLambda,
  runCardLambda,
  runWaxLambda,
  debugSingleQueryMatch,
  fetchDailyActiveAuctionsCounts,
  setMarketIndexOrder,
  syncOpenSearchIndex,
  getUserStats,
  runActiveMembersReports,
  runCustomActiveMembersReport,
};
