import React, { useEffect, useState } from "react";
import { DollarOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import Services from "../services";
import Helpers from "../services/helpers";
import { isMobile } from "react-device-detect";
import { ENTITY_TYPES } from "utils/constants";

const AffiliateLink = ({
  center,
  cardId,
  sealedWaxId,
  query,
  text,
  campaign,
  abbreviated,
  expanded = false,
  tooltipPlacement = "top",
  entityType = ENTITY_TYPES.CARD,
  ...props
}) => {
  const [id, getOneFn] = {
    [ENTITY_TYPES.CARD]: [cardId, "getCard"],
    [ENTITY_TYPES.SEALED_WAX]: [sealedWaxId, "getSealedWax"],
  }[entityType];

  const [params, setParams] = useState();

  useEffect(() => {
    let abortController;

    if (query) {
      setParams(query);
    } else if (id && !params) {
      abortController = new AbortController();
      Services[getOneFn](id, { excludeResults: true }, abortController.signal).then((entity) => {
        setParams(entity.query);
      });
    }

    return () => {
      abortController && abortController.abort();
    };
  }, [query, id]);

  const clickBtn = () => {
    window.open(Helpers.generatePartnerLinkFromQuery(campaign, params));
  };

  const style = {};
  if (center) {
    style.marginLeft = "65px";
    style.marginRight = "auto";
    style.marginTop = ".25rem";
  }

  if (expanded) {
    const link = text ? (
      text
    ) : (
      <>
        For Sale On eBay (Ad)&nbsp;
        <DollarOutlined />
      </>
    );
    return (
      <Button
        type="link"
        {...props}
        onClick={() => {
          if (!isMobile) {
            clickBtn();
          }
        }}
        onTouchEnd={clickBtn}
      >
        <span style={style}>{link}</span>
      </Button>
    );
  } else {
    const btn = (
      <Button
        {...props}
        type="link"
        style={{ padding: "0 5px" }}
        abbreviated={abbreviated ? abbreviated.toString() : "false"}
        onClick={() => {
          if (!isMobile) {
            clickBtn();
          }
        }}
        onTouchEnd={clickBtn}
      >
        <span style={style}>
          <DollarOutlined className="blue" />
        </span>
      </Button>
    );

    if (isMobile) {
      return btn;
    }

    return (
      <Tooltip title="For Sale on eBay (Ad)" placement={tooltipPlacement}>
        {btn}
      </Tooltip>
    );
  }
};

export { AffiliateLink };
