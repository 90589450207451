const STATUS_CODES = {
  UNAUTHORIZED: 401,
};

const ERRORS = {
  UNAUTHORIZED: "UNAUTHORIZED",
  BUYER_UNAVAILABLE: "BUYER_UNAVAILABLE",
  PLAYER_EXISTS_WITH_SAME_SPORT: "PLAYER_EXISTS_WITH_SAME_SPORT",
  SET_EXISTS_WITH_SAME_YEAR: "SET_EXISTS_WITH_SAME_YEAR",
  VARIATION_EXISTS_WITH_SAME_NAME: "VARIATION_EXISTS_WITH_SAME_NAME",
  SPORT_EXISTS_WITH_SAME_NAME: "SPORT_EXISTS_WITH_SAME_NAME",
};

const SAVED_SEARCH_TYPES = {
  SALES_LOOKUP: "SALES_LOOKUP",
  PLAYER_CHARTS: "PLAYER_CHARTS",
  SEALED_WAX_CHARTS: "SEALED_WAX_CHARTS",
  SET_AND_YEAR: "SET_AND_YEAR",
  SEALED_WAX: "SEALED_WAX",
  GRADE_RATIOS: "GRADE_RATIOS",
  VARIATION_RATIOS: "VARIATION_RATIOS",
  PLAYER_RATIOS: "PLAYER_RATIOS",
};

const FAVORITE_TYPES = {
  CARD: "CARD",
  PLAYER: "PLAYER",
  SEALED_WAX: "SEALED_WAX",
};

const COLLECTION_TYPES = {
  SAVED_SEARCH: "SAVED_SEARCH",
  FAVORITES: "FAVORITES",
};

const EBAY_QUERY_MAX_LENGTH = 340;
const ALT_CAMPAIGN = "5338660859";
const EBAY_SEARCH_LINK = "https://www.ebay.com/sch/i.html?_nkw=";
const EBAY_HOME_PAGE = "https://www.ebay.com";

const POSSIBLE_EXCLUDED_SELLERS = ["probstein123", "pwcc_auctions", "pwcc_vault", "comc_consignment"];

const POSSIBLE_EXCLUDED_PLATFORMS = [
  { key: "ebay", title: "eBay" },
  { key: "myslabs", title: "My Slabs" },
];

const LOW_FEEDBACK_THRESHOLD = 10;

export {
  STATUS_CODES,
  ERRORS,
  SAVED_SEARCH_TYPES,
  EBAY_QUERY_MAX_LENGTH,
  EBAY_SEARCH_LINK,
  ALT_CAMPAIGN,
  EBAY_HOME_PAGE,
  POSSIBLE_EXCLUDED_SELLERS,
  POSSIBLE_EXCLUDED_PLATFORMS,
  LOW_FEEDBACK_THRESHOLD,
  FAVORITE_TYPES,
  COLLECTION_TYPES,
};
