export const ENTITY_TYPES = {
  CARD: "CARD",
  SEALED_WAX: "SEALED_WAX",
  PLAYER: "PLAYER",
};

export const EMPTY_ALERT_MODAL_DATA = {
  visible: false,
  cardId: null,
  sealedWaxId: null,
  name: "",
};

export const EMPTY_ADD_SALES_MODAL_DATA = {
  visible: false,
  entityId: null,
};

export const RAW_GRADES = [
  "Raw",
  "Raw TCG (Heavily Played)",
  "Raw TCG (Lightly Played)",
  "Raw TCG (Moderately Played)",
  "Raw TCG (Near Mint)",
];
